import { useContext, useEffect, useRef, useState } from 'react';
import { AuditLog } from '../../api/audit';
import * as api from '../../api';
import { formatDate, formatDateTimeShort } from '../../utils/date-utils';
import './AuditList.css';
import UserChip from '../user-chip/UserChip';
import { title } from 'case';
import { AppContext } from '../../App';

interface Props {
  entityType: AuditLog['entityType'];
  entityId: AuditLog['entityId'];
  preloadedAuditLogs?: AuditLog[];
}

const AuditList: React.FC<Props> = ({ entityId, entityType, preloadedAuditLogs }) => {
  const ctx = useContext(AppContext);
  const containerRef = useRef<HTMLDivElement>(null);
  const [auditLogs, setAuditLogs] = useState<AuditLog[]>(preloadedAuditLogs || []);

  const getAuditLogs = async () => {
    const res = await api.audit.list(entityType, entityId);
    if (res.data) {
      setAuditLogs(res.data);
    }
  };

  useEffect(() => {
    if (!preloadedAuditLogs) {
      getAuditLogs();
    } else {
      setAuditLogs(preloadedAuditLogs);
    }
    
  }, [entityId, entityType, preloadedAuditLogs]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [auditLogs]);

  return (
    <div ref={containerRef} className="audit-list__container">
      {auditLogs.map((auditLog) => {
        const dataKeys = Object.keys(auditLog.data);
        return (
          <div key={auditLog.id} className="audit-list__item box">
            <p className="audit-list__log">{auditLog.log}</p>

            {!!dataKeys.length && (
              <ul className="audit-list__data-list">
                {dataKeys.map((key) => {
                  let valueToDisplay = auditLog.data[key];
                  if (key.toLowerCase().includes('date')) {
                    try {
                      valueToDisplay = formatDate(valueToDisplay as string, ctx.user.company.locale, ctx.user.company.timezone);  
                    } catch (error) {
                      
                    }
                    
                  }

                  if (typeof valueToDisplay === 'string' && valueToDisplay.length === 36) {
                    // assume uuid
                    valueToDisplay = valueToDisplay.slice(0, 6) + '...';
                  }

                  if (typeof valueToDisplay === 'boolean') {
                    valueToDisplay = valueToDisplay ? 'Yes' : 'No';
                  }

                  return (
                    <li key={`${auditLog.id}_${key}`} className="audit-list__data">
                      {title(key)}: {valueToDisplay}
                    </li>
                  );
                })}
              </ul>
            )}
            <div className="audit-list__meta">
              <p className="audit-list__meta__user">
                <UserChip user={auditLog.user} small={true} />
              </p>
              <p className="audit-list__meta__date">{formatDateTimeShort(auditLog.createdAt, ctx.user.company.locale, ctx.user.company.timezone)}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default AuditList;
