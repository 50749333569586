import { useContext, useState } from 'react';
import { NewProcessFields } from '../../../../api/process/admin';
import Input from '../../../../components/input/Input';
import TagInput from '../../../../components/input/TagInput';
import './ProcessDetailsForm.css';
import TagSelect from '../../../../components/input/TagSelect';
import { AppContext } from '../../../../App';
import Select from '../../../../components/input/Select';

const emptyProcess: NewProcessFields = {
  name: '',
  description: '',
  itemName: '',
  itemDisplayField: '',
  itemFields: [],
  maxItems: 1,
  ownerGroups: [],
  supportingData: [],
  category: '',
};

interface Props {
  onSubmit: (formData: NewProcessFields) => void | Promise<void>;
  initialData?: Partial<NewProcessFields>;
  readOnly?: boolean;
}

const ProcessDetailsForm: React.FC<Props> = ({ onSubmit, initialData = {}, readOnly }) => {
  const ctx = useContext(AppContext);
  const [formData, setFormData] = useState<NewProcessFields>({ ...emptyProcess, ...initialData });

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit(formData);
  };

  const isFormValid = () => {
    return formData.name && !!formData.ownerGroups?.length;
  };

  const processCategoryOptions = ctx.user.company.processCategories.map((processCategory) => {
    return {
      value: processCategory,
      label: processCategory,
    }
  })

  return (
    <form onSubmit={handleFormSubmit}>
      <Input
        id="name"
        labelText="Name*"
        value={formData.name || ''}
        onChange={(name) => setFormData({ ...formData, name })}
        readOnly={readOnly}
        inputClassName="process-details-form__name-input"
      />
      <Input
        id="description"
        labelText="Description"
        value={formData.description || ''}
        onChange={(description) => setFormData({ ...formData, description })}
        readOnly={readOnly}
      />
      <Select
        id='category'
        options={processCategoryOptions}
        labelText='Category'
        value={formData.category}
        onChange={(category) => setFormData({ ...formData, category })}
        readOnly={readOnly}
      />
      <TagInput
        id="ownerGroups"
        labelText="Owner Groups*"
        value={formData.ownerGroups as unknown as string}
        onChange={(ownerGroups) =>
          setFormData({ ...formData, ownerGroups: ownerGroups as unknown as string[] })
        }
        small={true}
        helpText="The groups that will be able to see and manage this process."
        readOnly={readOnly}
        emptyMessage='Please specify at least 1 owner group'
      />
      {!readOnly && (
        <button className="button" disabled={!isFormValid()}>
          {initialData ? 'Save' : 'Create Process'}
        </button>
      )}
    </form>
  );
};

export default ProcessDetailsForm;
