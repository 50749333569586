import { RefObject, useContext } from 'react';
import { Note } from '../../../api/note';
import UserChip from '../../../components/user-chip/UserChip';
import { formatDateTimeShort } from '../../../utils/date-utils';
import './NoteList.css';
import { AppContext } from '../../../App';

interface Props {
  notes: Note[];
  containerRef: RefObject<HTMLDivElement>
}

const NoteList: React.FC<Props> = ({ notes, containerRef }) => {
  const ctx = useContext(AppContext);

  return (
    <div className="note-list" ref={containerRef}>
      {notes.map((note) => (
        <div key={note.id} className="box note-list__item">
          <p className='note-list__text'>{note.text}</p>
          <div className="note-list__meta">
            <p className="note-list__meta__user">
              <UserChip user={note.user} small={true} />
            </p>
            <p className="note-list__meta__date">{formatDateTimeShort(note.createdAt, ctx.user.company.locale, ctx.user.company.timezone)}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default NoteList;
