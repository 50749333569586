import { baseMethods } from '..';
import { User } from '../auth';

export const list = async (search?: string) => {
  return baseMethods.get<User[]>({
    path: '/admin/users',
    params: { search },
  });
};

export const create = async (name: string, email: string, roles?: string[], userGroups?: string[]) => {
  return baseMethods.post({
    path: '/admin/user',
    body: { name, email, roles, userGroups },
  });
};

export const updateCompany = async (updates: Partial<Pick<User['company'], 'backgroundColour' | 'processCategories'>>) => {
  return baseMethods.patch({
    path: '/admin/company',
    body: updates,
  });
}

export const update = async (userId: string, updates: Partial<User>) => {
  return baseMethods.patch({
    path: `/admin/user/${userId}`,
    body: updates,
  });
}
