import { Fragment } from 'react';
import './PriceCard.css';

interface Props {}

const PriceCard: React.FC<Props> = () => {
  return (
    <Fragment>
      <div className="box price-card">
        <div>
          <h3 className="price-card__price">
            <span className="price-card__unit">$</span>99
          </h3>
          <small className="price-card__per-month"> per month</small>
        </div>
        <div>
          <ul className="price-card__list">
            <li>Unlimited processes</li>
            <li>Unlimited users</li>
            <li>Unlimited automation support</li>
          </ul>
        </div>
      </div>
      <p className='price-card__trial'>1st month free! Cancel any time</p>
    </Fragment>
  );
};

export default PriceCard;
