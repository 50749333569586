import { Fragment, useEffect, useState } from 'react';
import { Step } from '../../../../api/process';
import Input from '../../../../components/input/Input';
import './EditEdge.css';

interface Props {
  allSteps: Step[];
  stepId: string;
  previousStepId: string;
  updateStep: (step: Step) => void;
  handlePreviousStepRemove: (stepToUpdateId: string, previousStepIdToRemove: string) => void;
}

const EditEdge: React.FC<Props> = ({
  allSteps,
  stepId,
  previousStepId,
  updateStep,
  handlePreviousStepRemove,
}) => {
  const step = allSteps.find((step) => step.id === stepId);
  const previousStep = allSteps.find((step) => step.id === previousStepId);
  const initialLabel = step?.previousSteps?.find((step) => step.id === previousStepId)?.label;
  const [previousStepLabel, setPreviousStepLabel] = useState<string>(initialLabel || '');

  useEffect(() => {
    const step = allSteps.find((step) => step.id === stepId);
    const initialLabel = step?.previousSteps?.find((step) => step.id === previousStepId)?.label;
    setPreviousStepLabel(initialLabel || '');
  }, [stepId, previousStepId]);

  const handlePreviousStepLabelSave = () => {
    if (!step) return;
    const updatedStep = {
      ...step,
      previousSteps: step.previousSteps?.map((previousStep) => {
        if (previousStep.id === previousStepId) {
          return {
            ...previousStep,
            label: previousStepLabel,
          };
        }
        return previousStep;
      }),
    };

    updateStep(updatedStep);
  };
  return (
    <Fragment>
      {step && previousStep && (
        <Fragment>
          <h3>Edit connection:</h3>
          <span className="edit-edge__label">{previousStep.name}</span>
          <span className="edit-edge__label"> to </span>
          <span className="edit-edge__label">{step.name}</span>

          <div className="edit-edge__edit-label">
            <Input
              id="previous-step-label"
              labelText="Connection label"
              value={previousStepLabel}
              onChange={setPreviousStepLabel}
            />
            <button onClick={handlePreviousStepLabelSave} className="button button--small">
              Save
            </button>
          </div>

          <button
            className="button button--secondary edit-edge__delete-button"
            onClick={() => handlePreviousStepRemove(stepId, previousStepId)}
          >
            Delete connection
          </button>
        </Fragment>
      )}
    </Fragment>
  );
};

export default EditEdge;
