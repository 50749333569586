import { Fragment, useState } from 'react';
import Input from '../../../../components/input/Input';

interface Props {
  guidanceNotes: string
  handleSave: (guidanceNotes: string) => void;
}

const EditGuidanceNotes: React.FC<Props> = ({ guidanceNotes, handleSave}) => {
  const [editedGuidanceNotes, setEditedGuidanceNotes] = useState<string>(guidanceNotes || '')

  return (
    <Fragment>
      <h2>Add guidance notes</h2>
      <Input
        id="step-guidance-notes"
        type="textarea"
        labelText="Guidance notes"
        value={editedGuidanceNotes}
        onChange={setEditedGuidanceNotes}
        inputStyle={{ width: '100%', minHeight: '60vh' }}
      />
      <button className='button' onClick={() => handleSave(editedGuidanceNotes)}>Save</button>
    </Fragment>
  );
};

export default EditGuidanceNotes;
