import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline'
import './HelpLink.css'
import { HTMLAttributes } from 'react'

interface Props {
  to: string
  text: string
  style?: HTMLAttributes<HTMLAnchorElement>['style']
}

const HelpLink: React.FC<Props> = ({ to, text, style }) => {

  return (
    <a href={to} className='help-link' target='_blank' style={style}>
      <QuestionMarkCircleIcon />
      {text}
    </a>
  )
}

export default HelpLink