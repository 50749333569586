import { Fragment } from 'react'
import { Integration } from '../../api/integration/admin'
import { BoltIcon } from '@heroicons/react/24/outline'

interface Props {
  integrationType: Integration['type']
}

const IntegrationIcon: React.FC<Props> = ({ integrationType }) => {

  return (
    <Fragment>
      {integrationType === 'API' && <BoltIcon />}
      {integrationType === 'SHOPIFY' && <img src="/shopify-logo.png" className='icon-img' />}
    </Fragment>
  )
}

export default IntegrationIcon