import { baseMethods } from '..'

export type DataMapping = {
  id: string
  inputKey: string
  outputKey: string
}

export type Integration = {
  id: string
  type: 'SHOPIFY' | 'API'
  uniqueCaseField: DataMapping
  dataMappings: DataMapping[]
  webhookId: string
  webhookSecret?: string
  caseOwnerUserId: string
}

export const create = async (processId: string, newCase: Partial<Integration>) => {
  return baseMethods.post<Integration>({
    path: `/integrations/case-creation/${processId}`,
    body: newCase
  })
}
export const update = async (integrationId: string, updates: Partial<Integration>) => {
  return baseMethods.patch<Integration>({
    path: `/integrations/${integrationId}`,
    body: updates,
  })
}

export const request = async (processId: string, details: string, processStepId?: string) => {
  return baseMethods.post({
    path: '/integrations/request',
    body: {
      processId,
      details,
      processStepId,
    }
  })
}