import { Fragment } from 'react'
import { ItemField } from '../../api/process'
import Input from '../input/Input'
import { title } from 'case'
import FileUpload from '../file-upload/FileUpload'
import FileListItem from '../file-list/FileListItem'
import './ItemFields.css'
import { UploadedFile } from '../../api/file'
import { validateValue } from './utils'
import Select from '../input/Select'

interface Props {
  fields: ItemField[]
  isEditing: boolean
  values: any
  setValues: (values: any) => void
  entityId: string
  entityType?: UploadedFile['entityType']
}

const ItemFields: React.FC<Props> = ({ fields, values, setValues, isEditing, entityId, entityType = 'CASE' }) => {
  return (
    <Fragment>
      {fields.map((field) => {
        const label = `${field.label || title(field.name)}${field.requiredOnCreate ? '*' : ''}`
        const value = values[field.name] || ''
        return (
          <div key={field.name}>
            {(field.type !== 'file' && field.type !== 'select') && (
              <Input
                id={field.name}
                type={field.type}
                labelText={label}
                value={value as string}
                onChange={(value: string) => setValues({ ...values, [field.name]: value })}
                readOnly={!isEditing}
                invalid={!validateValue(field, value)}
                minLength={field.minLength}
                maxLength={field.maxLength}
              />
            )}
            {field.type === 'select' && (
              <Select
                id={field.name}
                key={field.name}
                labelText={label}
                value={value}
                onChange={(value: string) => setValues({ ...values, [field.name]: value })}
                options={field.options || []}
                invalid={!validateValue(field, value)}
              />
            )}
            {field.type === 'file' && (
              <div className="item-field__file">
                <p style={{ margin: 0, fontWeight: 'bold' }} className="input__label">
                  {label}
                </p>
                {!value && !!isEditing && (
                  <FileUpload
                    entityId={entityId}
                    entityType={entityType}
                    onUploadFn={(uploadedFile) => {
                      setValues({ ...values, [field.name]: uploadedFile.id })
                    }}
                  />
                )}
                {!value && !isEditing && <small>No file added</small>}
                {!!value && (
                  <div style={{ width: 'min-content' }}>
                    <FileListItem fileId={value} />
                    {isEditing && (
                      <button
                        style={{ textAlign: 'center', width: '100%', fontSize: '12px' }}
                        className="link-button"
                        onClick={() => setValues({ ...values, [field.name]: '' })}
                      >
                        Remove
                      </button>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        )
      })}
    </Fragment>
  )
}

export default ItemFields
