import AsyncSelect from 'react-select/async';
import * as api from '../../api';
import styles from './Input.module.css'

interface Props {
  id: string
  labelText: string
  onSelect: (userId: string) => void | Promise<void>;
  userIdsToExclude: string[];
}

const NoOptionsMessage = ({ inputValue }: any) => {
  if (!inputValue) {
    return <p>Start typing to search by name or email</p>;
  }

  return <p>Try using a less specific search term.</p>;
};

const UserSelect: React.FC<Props> = ({ userIdsToExclude, onSelect, id, labelText }) => {
  const handleSearch = async (query: string) => {
    const res = await api.users.list(query);
    if (res.data) {
      return res.data.users
        .filter((user) => !userIdsToExclude.includes(user.id))
        .map((user) => {
          return {
            label: `${user.name} (${user.email})`,
            value: user.id,
          };
        });
    }

    return [];
  };

  return (
    <div className={styles['input__container']}>
      <label className={styles['input__label']} htmlFor={id}>
        {labelText}
      </label>
    <AsyncSelect
      id={id}
      loadOptions={handleSearch}
      onChange={(val) => onSelect(val ? val.value : '')}
      placeholder="Search..."
      noOptionsMessage={NoOptionsMessage}
      
    />
    </div>
  );
};

export default UserSelect;
