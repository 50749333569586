import { baseMethods } from '..';
import { Case } from '../case';

export type SearchEntity = {
  value: string;
  label: string;
}

export type SearchResult = {
  case: Case;
  relevantData: Record<string, string>;
};

export const getEntities = async () => {
  return baseMethods.get<SearchEntity[]>({
    path: '/search/entities',
  })
}

export const get = async (entity: string, query: string) => {
  return baseMethods.get<SearchResult[]>({
    path: '/search',
    params: {
      entity,
      query,
    },
  });
}