import { FormEvent, Fragment, useState } from 'react';
import { ItemField } from '../../api/process';
import './ItemFieldForm.css';
import ItemFields from './ItemFields';

interface Props {
  formTitle: string
  initialValues: any;
  fields: ItemField[];
  editOnRender?: boolean;
  onSubmit: (values: any) => void | Promise<void>;
  preventEdit?: boolean;
  caseId: string;
}

const ItemFieldForm: React.FC<Props> = ({ initialValues, editOnRender, fields, onSubmit, formTitle, preventEdit, caseId }) => {
  const [isEditing, setIsEditing] = useState(editOnRender || false);
  const [values, setValues] = useState(initialValues);

  const handleCancel = () => {
    setValues(initialValues);
    setIsEditing(false);
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    await onSubmit(values);
    setIsEditing(false);
  };

  return (
    <Fragment>
      <div className="item-field-form__header">
        <h3 className="item-field-form__title">{formTitle}</h3>
        {(!isEditing && !preventEdit) && (
          <button className="link-button" onClick={() => setIsEditing(true)}>
            Edit
          </button>
        )}
      </div>
      <form onSubmit={handleSubmit}>
        <ItemFields fields={fields} values={values} setValues={setValues} isEditing={isEditing} entityId={caseId} />
        {!!isEditing && (
          <div className="item-field-form__button-container">
            <button className="button button--small">Save</button>
            <button type="button" onClick={handleCancel} className="button button--small button--secondary">
              Cancel
            </button>
          </div>
        )}
      </form>
    </Fragment>
  );
};

export default ItemFieldForm;
