import React, { memo, useContext, useEffect } from 'react';
import { Handle, Position, useReactFlow } from 'reactflow';
import './DecisionNode.css';
import { Step } from '../../api/process';
import DecisionStep from '../../components/steps/DecisionStep';
import { ProcessMapContext } from './ProcessMapInner';
import { calculateHandles } from './utils';

interface Props {
  data: { step: Step; label: string; nextSteps: Step[]; previousSteps: Step[] };
  isConnectable: boolean;
  xPos: number;
  yPos: number;
}

const DecisionNode: React.FC<Props> = memo(({ isConnectable, data, xPos, yPos }) => {
  const ctx = useContext(ProcessMapContext);
  const { setCenter } = useReactFlow();
  const focusNode = () => {
    if (data.step.current === true) {
      const x = xPos + 150 / 2;
      const y = yPos + 150 / 2;
      const zoom = 1.1;
      setCenter(x, y, { zoom, duration: 500 });
    }
  };

  useEffect(() => {
    focusNode();
  }, [data.step.current]);

  const handleClick = (position: Position) => {
    if (!ctx.source) {
      ctx.setSource({ id: data.step.id, position });
      return;
    }

    ctx.setTarget({ id: data.step.id, position });
  };

  const isSelected = (position: Position) => {
    if (ctx.source && ctx.source.id === data.step.id && ctx.source.position === position) {
      return true;
    }

    if (ctx.target && ctx.target.id === data.step.id && ctx.target.position === position) {
      return true;
    }

    return false;
  };

  const nodeHasSelectedHandle = ctx.source?.id === data.step.id || ctx.target?.id === data.step.id;

  const { hasLeftHandle, hasTopHandle, hasRightHandle, hasBottomHandle } = calculateHandles(data);

  return (
    <>
      {hasLeftHandle && (
        <Handle
          type={hasLeftHandle}
          position={Position.Left}
          id="left"
          style={{ background: '#555', left: -32, zIndex: 10 }}
          onConnect={(params) => console.log('handle onConnect', params)}
          isConnectable={isConnectable}
        />
      )}
      {(!hasLeftHandle && ctx.isEditable) && (
        <button
          onClick={() => handleClick(Position.Left)}
          className={`step-select-button ${isSelected(Position.Left) ? 'step-select-button--selected' : ''}`}
          style={{ top: 'calc(50% - 11px)', left: '-35px', zIndex: 10 }}
          disabled={nodeHasSelectedHandle}
        >
          +
        </button>
      )}

      <DecisionStep step={data.step} />

      {hasTopHandle && (
        <Handle
          type={hasTopHandle}
          position={Position.Top}
          id="top"
          style={{ top: -32, left: 75, background: '#555' }}
          isConnectable={isConnectable}
        />
      )}
      {(!hasTopHandle && ctx.isEditable) && (
        <button
          onClick={() => handleClick(Position.Top)}
          className={`step-select-button ${isSelected(Position.Top) ? 'step-select-button--selected' : ''}`}
          style={{ top: '-35px', left: 'calc(50% - 10px)' }}
          disabled={nodeHasSelectedHandle}
        >
          +
        </button>
      )}

      {hasRightHandle && (
        <Handle
        type={hasRightHandle}
        position={Position.Right}
        id="right"
        style={{ bottom: 69, right: -31, top: 'auto', background: '#555' }}
        isConnectable={isConnectable}
      />
      )}
      {(!hasRightHandle && ctx.isEditable) && (
        <button
          onClick={() => handleClick(Position.Right)}
          className={`step-select-button ${isSelected(Position.Right) ? 'step-select-button--selected' : ''}`}
          style={{ top: 'calc(50% - 11px)', right: '-35px', zIndex: 10 }}
          disabled={nodeHasSelectedHandle}
        >
          +
        </button>
      )}
      {hasBottomHandle && (
        <Handle
        type={hasBottomHandle}
        position={Position.Bottom}
        id="bottom"
        style={{ bottom: -32, background: '#555' }}
        isConnectable={isConnectable}
      />
      )}
      {(!hasBottomHandle && ctx.isEditable) && (
        <button
          onClick={() => handleClick(Position.Bottom)}
          className={`step-select-button ${isSelected(Position.Bottom) ? 'step-select-button--selected' : ''}`}
          style={{ bottom: '-35px', left: 'calc(50% - 10px)' }}
          disabled={nodeHasSelectedHandle}
        >
          +
        </button>
      )}
    </>
  );
});

export default DecisionNode;
