import { title } from "case";
import { Case, CaseStep } from "../../api/case";
import { ItemField, Step } from "../../api/process";

export const ownerGroupToTitleCase = (ownerGroup: string) => {
  if (ownerGroup.length <= 2) {
    return ownerGroup
  }

  return title(ownerGroup)
}

export const getActiveCaseStep = (_case?: Case) => {
  return _case?.caseSteps.find((step) => !step.completedAt && !step.stepCompletedDate);
}

export const getNextSteps = (_case: Case, currentProcessStepId: string) => {
  const nextSteps = _case.process.steps.filter((step) => {
    return step.previousSteps?.find((previousStep) => previousStep.id === currentProcessStepId);
  })

  return nextSteps
}

export const canUserCompleteCurrentStep = (_case: Case, currentProcessStepId: string, userId: string) => {
  const currentStep = _case.process.steps.find((step) => step.id === currentProcessStepId);

  if (!currentStep) {
    return false;
  }

  const userOwnerGroups = _case.caseUsers.find((caseUser) => caseUser.userId === userId)?.roles;
  if (!userOwnerGroups) {
    return false;
  }

  const userIsOwner = userOwnerGroups.some((role) => currentStep.ownerGroups.includes(role));

  return userIsOwner;
}

export const stepHasSupportingDataCompleted = (caseStep: CaseStep, processStep: Step) => {
  let hasAllSupportingData = true
  if (processStep.supportingData) {
    hasAllSupportingData = validateItemFields(processStep.supportingData, caseStep.supportingData)
  }

  return hasAllSupportingData
}


export const isStepCompletable = (caseStep: CaseStep, processStep: Step) => {

  const errors: string[] = []

  if (processStep.type === 'DECISION' && !caseStep.decision)  {
    errors.push('a decision is required')
  }

  if (processStep.type === 'CONTROL' && !caseStep.controlOutcome) {
    errors.push('a control outcome is required')
  }

  const hasAllSupportingData = stepHasSupportingDataCompleted(caseStep, processStep)
  if (!hasAllSupportingData) {
    errors.push('all required fields must be completed')
  }

  if (processStep.automation?.type === 'LETTER' && processStep.automation.letter) {
    const hasGeneratedLetter = !!caseStep.supportingData[processStep.automation.letter.name]
    if (!hasGeneratedLetter) {
      errors.push('a letter must be generated')
    }
  }

  return {
    isCompletable: errors.length === 0,
    errors
  }
}

export const validateItemFields = (itemFields: ItemField[], data: any) => {
  let itemFieldsCompleted = true
  itemFields.forEach((field) => {
    if (!data[field.name] && field.requiredOnCreate) {
      itemFieldsCompleted = false
    }
  })

  return itemFieldsCompleted
}

export function validateValue(value: any, type: ItemField['type']): boolean {
  switch (type) {
      case 'text':
          return typeof value === 'string';
      case 'number':
          return typeof Number(value) === 'number' && !isNaN(Number(value));
      case 'date':
          const dateValue = new Date(value);
          return dateValue instanceof Date && !isNaN(dateValue.getTime());
      default:
          return true
  }
}