import { FormEvent, Fragment, useContext, useEffect, useState } from 'react';
import * as api from '../../../api';
import { Process, Step } from '../../../api/process';
import Select from '../../../components/input/Select';
import { TrashIcon } from '@heroicons/react/24/outline';
import './ProcessStepForm.css';
import SearchableSelect from 'react-select';
import Input from '../../../components/input/Input';
import ItemFieldConfigList from '../configure-item-field/ItemFieldConfigList';
import { camel } from 'case';
import Modal from '../../../components/modal/Modal';
import { Case } from '../../../api/case';
import CaseStep from '../../case/case-step/CaseStep';
import { AppContext } from '../../../App';

interface Props {
  process: Process;
  stepToUpdate: Step;
  allSteps: Step[];
  updateAllSteps: (steps: Step[]) => void;
}

const ProcessStepForm: React.FC<Props> = ({ stepToUpdate, allSteps, updateAllSteps, process }) => {
  const ctx = useContext(AppContext);
  const [step, setStep] = useState<Step>(stepToUpdate);
  const [showMore, setShowMore] = useState(false);

  const trackNumberOptions = [
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
  ];

  const stepTypeOptions = [
    { value: 'MANUAL', label: 'Manual step' },
    { value: 'DECISION', label: 'Decision' },
    { value: 'LETTER', label: 'Auto generated letter' },
  ];

  const previousSteps = allSteps.filter((stepFromAllSteps) => step.previousStepIds?.includes(stepFromAllSteps.id));

  const handleDeletePreviousStep = (stepId: string) => {
    const updatedPreviousStepIds = step.previousStepIds?.filter((previousStepId) => previousStepId !== stepId);
    setStep({ ...step, previousStepIds: updatedPreviousStepIds });
  };

  const handleAddPreviousStep = (stepId: string) => {
    const updatedPreviousStepIds = step.previousStepIds ? [...step.previousStepIds, stepId] : [stepId];
    setStep({ ...step, previousStepIds: updatedPreviousStepIds });
  };

  const handleFormSubmit = async (e: FormEvent) => {
    e.preventDefault();

    const stepToSend = {...step}
    if (stepToSend.automation?.letter?.name) {
      stepToSend.automation.letter.name = camel(stepToSend.automation.letter.name)
    }
    const res = await api.processes.admin.updateStep(stepToUpdate.id, stepToSend);
    if (res.data) {
      const updatedAllSteps = allSteps.map((stepFromAllSteps) => {
        if (stepFromAllSteps.id === step.id) {
          return step;
        }

        return stepFromAllSteps;
      });
      updateAllSteps(updatedAllSteps);
    }
  };

  const handleCancel = () => {
    setStep(stepToUpdate);
  };

  useEffect(() => {
    setStep(stepToUpdate);
  }, [stepToUpdate]);

  const [isOpen, setIsOpen] = useState(false)

  const fakeCase: Case = {
    id: 'fake-case-id',
    caseOwnerUserId: '5135',
    caseUsers: process.ownerGroups.map((ownerGroup, index) => {
      return {
        caseId: '2134124',
        id: String(index),
        roles: [ownerGroup],
        user: ctx.user,
        userId: ctx.user.id,
      }
    }),
    code:'001',
    companyId: '12421414',
    items: [],
    processId: '244211',
    createdAt: '32523532',
    completionPercentage: 0.6,
    status: 'ACTIVE',
    supportingData: {},
    caseSteps: [
      {
        caseId: 'active-step',
        companyId: '',
        createdAt: new Date(),
        id: 'active-step',
        processStepId: step.id,
        stepStartedDate: new Date(),
        supportingData: {}
      }
    ],
    process: {
      ...process,
      steps: allSteps
    }
  }

  return (
    <Fragment>
      {/* button goes here to open modal */}
      <button className="link-button" onClick={() => setIsOpen(true)}> Preview Step </button>
      <Modal isOpen={isOpen} handleClose={() => setIsOpen(false)} >
        <h1>Case step preview</h1>
        <CaseStep
         _case={fakeCase}
         caseStepId="active-step"
         updateCase={() => {}}
         />
      </Modal>
      <form onSubmit={handleFormSubmit}>
        <Input
          id="step-number"
          labelText="Step Number"
          helpText="This only determines the order it is rendered on the process map"
          value={String(step.stepNumber)}
          onChange={(value) => setStep({ ...step, stepNumber: Number(value) })}
          type="number"
        />
        <Select
          id="step-track"
          labelText="Track"
          options={trackNumberOptions}
          value={String(step.track)}
          onChange={(track) => setStep({ ...step, track: Number(track) })}
          helpText="Vertical position on process map"
        />
        <div className="process-step-form__previous-step-input">
          <p className="process-step-form__previous-step-label">Previous steps</p>
          {previousSteps.map((previousStep) => {
            return (
              <div key={previousStep.id} className="process-step-form__previous-step">
                <span>{previousStep.name}</span>
                <button type="button" className="link-button" onClick={() => handleDeletePreviousStep(previousStep.id)}>
                  <TrashIcon />
                </button>
              </div>
            );
          })}
          <SearchableSelect
            id="add-previous-step"
            placeholder="Add previous step"
            value={null}
            options={allSteps
              .map((step) => ({ value: step.id, label: step.name }))
              .filter((stepOption) => !step.previousStepIds?.includes(stepOption.value))}
            onChange={(value) => handleAddPreviousStep(value?.value as string)}
          />
        </div>

        <Input
          id="previous-step-label"
          labelText="Previous step label"
          value={step.previousStepLabel || ''}
          onChange={(value) => setStep({ ...step, previousStepLabel: value })}
        />
        {!!showMore && (
          <Fragment>
            <Select
              id="step-type"
              labelText="Type"
              options={stepTypeOptions}
              value={step.type}
              onChange={(type) => setStep({ ...step, type })}
            />
            <Input
              id="step-name"
              labelText="Step Name"
              value={step.name || ''}
              onChange={(name) => setStep({ ...step, name })}
            />
            <Input
              id="guidance-notes"
              labelText="Guidance notes"
              value={step.guidanceNotes || ''}
              onChange={(value) => setStep({ ...step, guidanceNotes: value })}
              type="textarea"
            />

            <Input
              id="due-in-days"
              labelText="Due in (days)"
              value={String(step.dueInDays)}
              onChange={(value) => setStep({ ...step, dueInDays: Number(value) })}
              type="number"
            />
            <ItemFieldConfigList
              labelText="Step data"
              itemFields={step.supportingData || []}
              onChange={(supportingData) => setStep({ ...step, supportingData })}
            />
            {/* <ConfigureAutomation
              stepAutomation={step.automation}
              onChange={(automation) => setStep({ ...step, automation })}
              stepId={step.id}
              process={process}
              allSteps={allSteps}
            /> */}
          </Fragment>
        )}
        <button
          type="button"
          className="link-button"
          onClick={() => setShowMore(!showMore)}
          style={{ marginBottom: '32px' }}
        >
          {showMore ? 'Show less...' : 'Show more...'}
        </button>
        <div className="process-step-form__actions">
          <button className="button">Save</button>
          <button className="button button--secondary button--small" type="reset" onClick={handleCancel}>
            Cancel
          </button>
        </div>
      </form>
    </Fragment>
  );
};

export default ProcessStepForm;
