import { FormEvent, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { Step } from '../../../../api/process';
import Input from '../../../../components/input/Input';
import Select from '../../../../components/input/Select';
import './AddStep.css';
import TagSelect from '../../../../components/input/TagSelect';

interface Props {
  ownerGroups: string[];
  onStepAdd: (step: NewStep) => void;
}

export type NewStep = Pick<Step, 'id' | 'name' | 'type' | 'ownerGroups' | 'mapPosition'>;

const emptyStep = (): NewStep => ({
  id: uuid(),
  name: '',
  type: 'MANUAL',
  ownerGroups: [],
  mapPosition: {
    x: 0,
    y: 0,
  },
});

const AddStep: React.FC<Props> = ({ onStepAdd, ownerGroups }) => {
  const [newStep, setNewStep] = useState<NewStep>(emptyStep());

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    onStepAdd(newStep);
    setNewStep(emptyStep());
  };

  return (
    <form onSubmit={handleSubmit}>
      <h3>Add a new step</h3>
      <Input
        id="step-name"
        labelText="Name"
        value={newStep.name}
        onChange={(name) => setNewStep({ ...newStep, name })}
      />

      <TagSelect
        availableTags={ownerGroups}
        selectedTags={newStep.ownerGroups}
        labelText="Owner Groups"
        helpText="Only users in the owner groups selected can complete this step"
        onChange={(ownerGroups) => setNewStep({ ...newStep, ownerGroups })}
      />

      <Select
        id="step-type"
        labelText="Type"
        value={newStep.type}
        onChange={(type) => setNewStep({ ...newStep, type })}
        options={[
          { label: 'Step', value: 'MANUAL' },
          { label: 'Decision', value: 'DECISION' },
          { label: 'Control', value: 'CONTROL' },
        ]}
      />
      <button
        className="button button--small"
        disabled={!newStep.type || !newStep.name || !newStep.ownerGroups.length}
      >
        Add step
      </button>
    </form>
  );
};

export default AddStep;
