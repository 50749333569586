import { FormEvent, useContext, useEffect, useState } from 'react';
import { Process } from '../api/process';
import * as api from '../api';
import Select from '../components/input/Select';
import { Link, useNavigate } from 'react-router-dom';
import './HomePage.css';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { AssignedCase, OutstandingTask } from '../api/case';
import Table, { HeaderComponent } from '../components/table/Table';
import DueDate from '../components/due-date/DueDate';
import Input from '../components/input/Input';
import { getBrand } from '../utils/brand';
import { AppContext } from '../App';
import { CogIcon } from '@heroicons/react/24/outline';
import { trackEvent } from '..';
import HelpLink from '../components/help-link/HelpLink';

interface Props {}

const HomePage: React.FC<Props> = () => {
  const navigate = useNavigate();
  const ctx = useContext(AppContext);
  const [processes, setProcesses] = useState<Process[]>([]);
  const [outstandingTasks, setOutstandingTasks] = useState<OutstandingTask[]>([]);
  const [includeCompletedCases, setIncludeCompletedCases] = useState(false);
  const [assignedCases, setAssignedCases] = useState<AssignedCase[]>([]);
  const [newCaseProcessId, setNewCaseProcessId] = useState('');

  const handleCreateCase = async (e: FormEvent) => {
    e.preventDefault();

    if (!newCaseProcessId) {
      return;
    }

    const res = await api.cases.create(newCaseProcessId);
    if (res.data) {
      trackEvent('caseCreated');
      navigate(`/case/${res.data.id}`);
    }
  };

  const getProcesses = async () => {
    const res = await api.processes.list();
    if (res.data) {
      setProcesses(res.data);
    }
  };

  const getOutstandingTasks = async () => {
    const res = await api.cases.getOutstandingTasks();
    if (res.data) {
      setOutstandingTasks(res.data);
    }
  };

  const getAssignedCases = async () => {
    const res = await api.cases.getAssignedCases(includeCompletedCases);
    if (res.data) {
      setAssignedCases(res.data);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      getProcesses();
      getOutstandingTasks();
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      getAssignedCases();
    });
  }, [includeCompletedCases]);

  const idHeader: HeaderComponent<OutstandingTask> = (record) => {
    return {
      element: <Link to={`/case/${record?.caseId}`}>{record?.nickname ? `${record?.nickname} (${record?.code})` : record?.code}</Link>,
      header: 'Case',
    };
  };
  const dueDateHeader: HeaderComponent<OutstandingTask> = (record) => {
    return {
      header: 'Due date',
      element: record?.dueDate ? <DueDate date={record.dueDate} /> : '',
    };
  };

  const caseIdHeader: HeaderComponent<AssignedCase> = (record) => {
    return {
      element: <Link to={`/case/${record?.caseId}`}>{record?.nickname ? `${record?.nickname} (${record?.code})` : record?.code}</Link>,
      header: 'Case',
    };
  };

  const caseHeadersToIncludes = Object.keys(assignedCases[0] || {}).filter(
    (key) => key !== 'id' && key !== 'code' && key !== 'caseId',
  );

  return (
    <main className="content">
      <div className="home-page__upper-container">
        <div className="box home-page__search">
          <h1>Welcome to {getBrand() === 'operonix' ? 'Operonix' : 'ER Buddy'}</h1>
          <p>Have a nice day 😀</p>
        </div>
        <div className="box home-page__new-case">
          <h3>Create a new case</h3>
          <form onSubmit={handleCreateCase}>
            <Select
              id="new-case-process"
              labelText="Case type"
              value={newCaseProcessId}
              onChange={setNewCaseProcessId}
              options={processes.map((process) => ({
                label: process.name,
                value: process.id,
              }))}
            />
            <button className="button" disabled={!newCaseProcessId}>
              Create case
            </button>
          </form>
        </div>
      </div>
      {!processes.length && ctx.user.roles.includes('admin') && (
        <div className='box'>
          <p>It looks like you haven't got any processes set up or published yet</p>
          <Link to="/admin" className='button' style={{ paddingLeft: '22px'}}><CogIcon style={{width: '34px'}} /> Go to admin</Link>
          <HelpLink to="https://operonix.com/get-started" text="Check out our getting started guide" style={{ marginTop: '16px' }} />
        </div>
      )}
      <div className="box home-page__case-list">
        <Tabs>
          <TabList>
            <Tab>Your outstanding tasks</Tab>
            <Tab>Your cases</Tab>
          </TabList>
          <TabPanel>
            <h3>Your outstanding tasks</h3>
            <Table data={outstandingTasks} headers={[idHeader, 'name', dueDateHeader]} />
          </TabPanel>
          <TabPanel>
            <h3>Your cases</h3>
            <Input
              id="include-completed-cases"
              labelText="Include completed cases"
              type="checkbox"
              value={includeCompletedCases ? 'true' : 'false'}
              onChange={(value) => setIncludeCompletedCases(value === 'true')}
            />
            <Table
              data={assignedCases}
              headers={[caseIdHeader, ...caseHeadersToIncludes]}
              search={true}
            />
          </TabPanel>
        </Tabs>
      </div>
    </main>
  );
};

export default HomePage;
