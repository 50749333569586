import { baseMethods } from '.';
import { User } from './auth';

export interface Note {
  id: string;
  createdAt: Date;
  entityType: 'CASE' | 'CASE_STEP' | 'LETTER_TEMPLATE';
  entityId: string;
  text: string;
  userId: string;
  user: User;
}

export const list = async (entityType: Note['entityType'], entityId: string) => {
  return baseMethods.get<Note[]>({
    path: `/${entityType.toLowerCase()}/${entityId}/notes`,
  });
}

export const create = async (entityType: Note['entityType'], entityId: string, text: string) => {
  return baseMethods.post<Note>({
    path: `/${entityType}/${entityId}/notes`,
    body: { text },
  });
}
