import { ClockIcon } from "@heroicons/react/24/outline"
import { calculateRagStatus, formatDateTime, formatDateTimeShort } from "../../utils/date-utils"
import './DueDate.css'
import { HTMLAttributes, useContext } from "react"
import { AppContext } from "../../App"

interface Props {
  date: Date | string
  style?: HTMLAttributes<HTMLSpanElement>['style']
  title?: string
}

const DueDate: React.FC<Props> = ({ date, style, title }) => {
  const ctx = useContext(AppContext);
  const dateToDisplay = formatDateTimeShort(date, ctx.user.company.locale, ctx.user.company.timezone)
  const rag = calculateRagStatus(date)
  const dateForTitle = formatDateTime(date, ctx.user.company.locale, ctx.user.company.timezone)
  return (
    <span style={style} title={`${title} | ${dateForTitle}`} className={`due-date due-date--${rag}`}>
      <ClockIcon />
      {dateToDisplay}
    </span>
  )
}

export default DueDate
