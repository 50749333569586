import { Fragment, useEffect, useState } from 'react';
import * as api from '../../api';
import Table, { TableHeader } from '../../components/table/Table';
import { User } from '../../api/auth';
import Modal from '../../components/modal/Modal';
import './UserAdmin.css';
import UserForm from '../../modules/admin/user-admin/UserForm';

interface Props {}

const UserAdmin: React.FC<Props> = () => {
  const [listUsers, setListUsers] = useState<User[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User>();

  async function getUsers() {
    const res = await api.users.admin.list();
    if (res.data) {
      setListUsers(res.data);
    }
  }

  useEffect(() => {
    setTimeout(() => {
      getUsers();
    });
  }, []);

  const handleUserCreationComplete = async () => {
    setIsOpen(false);
    getUsers();
  };

  const handleUserSelect = (user?: User) => {
    setSelectedUser(user);
    setIsOpen(true);
  };

  const tableHeaders: TableHeader<User>[] = [
    (user) => {
      return {
        header: 'id',
        element: (
          <button className="link-button" onClick={() => handleUserSelect(user)}>
            {user?.id.slice(0,6)}...
          </button>
        ),
      };
    },
    'email',
    'roles',
    'userGroups',
  ];

  return (
      <Fragment>
        <div className="title-and-button-inline">
          <h2>User Administration</h2>
          <button className="button button--secondary button--small" onClick={() => setIsOpen(true)}>
            Add new user
          </button>
        </div>
        <Modal isOpen={isOpen} handleClose={() => {setIsOpen(false); setSelectedUser(undefined)}} modalStyles={{ minWidth: '50vw'}}>
          <div className="add-new-user-textbox">
            <h2>{selectedUser ? 'Edit user' : 'Add new user'}</h2>
            <UserForm onComplete={handleUserCreationComplete} user={selectedUser} />
          </div>
        </Modal>
        <h2>Users</h2>
        <Table data={listUsers} headers={tableHeaders} />
      </Fragment>
  );
};

export default UserAdmin;
