import { Fragment, useEffect, useState } from 'react';
import { UploadedFile } from '../../api/file';
import * as api from '../../api';
import LoadingSpinner from '../loading-spinner/LoadingSpinner';

interface Props {
  fileId?: string;
  preloadedFile?: UploadedFile;
  refreshAuditLogs?: () => void;
}

const FileListItem: React.FC<Props> = ({ fileId, preloadedFile, refreshAuditLogs }) => {
  const [file, setFile] = useState<UploadedFile | undefined>(preloadedFile);

  const getFile = async () => {
    if (fileId) {
      const res = await api.files.get(fileId);
      if (res.data) {
        setFile(res.data);
      }
    }
  };

  useEffect(() => {
    getFile();
  }, [fileId]);

  const downloadFile = async (selectedFile: UploadedFile) => {
    const res = await api.files.download(selectedFile.entityId, selectedFile.entityType, selectedFile.id as string);
    if (res.data) {
      const downloadUrl = window.URL.createObjectURL(res.data as any);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', selectedFile.name);
      document.body.appendChild(link);
      link.click();
      link.remove();
      if (refreshAuditLogs) {
        refreshAuditLogs();
      }
    }
  };

  return (
    <div className="file-list__item box">
      {!!file && (
        <Fragment>
          {file.name}
          <button className="link-button" onClick={() => downloadFile(file)}>
            Download
          </button>
        </Fragment>
      )}
      {!file && <LoadingSpinner />}
    </div>
  );
};

export default FileListItem;
