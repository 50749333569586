
import { baseURL } from '../../../../api';
import { Integration } from '../../../../api/integration/admin';

export const generateApiUrl = (integration: Integration) => {
  let path = '/integrations'
  if (integration.type !== 'API') {
    path = `${path}/${integration.type.toLowerCase()}`
  }

  path = `${path}/webhook`

  if (integration.type === 'API') {
    path = `${path}/${integration.webhookId}`
  }

  return `${baseURL}${path}`
}