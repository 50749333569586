import { Fragment, useEffect } from "react";
import { useState } from "react";
import * as api from '../api'
import './ProfilePage.css';
import initials from 'initials';
import { PencilIcon } from "@heroicons/react/24/outline";
import Input from "../components/input/Input";
import OwnerGroupList from "../components/owner-groups/OwnerGroupsList";
import Select from "../components/input/Select";


function ProfilePage() {

    const [userName, setUserName] = useState('')
    const [userEmail, setUserEmail] = useState('')
    const [userInitials, setUserInitials] = useState('')
    const [roles, setRoles] = useState<string[]>([])
    const [isEditing, setIsEditing] = useState(false)
    const [avatarColour, setAvatarColour] = useState('')
    const [webBackgroundColour, setWebBackgroundColour] = useState('')


    async function getProfileDetailsFromBackend() {
        const res = await api.users.me()
        if (res.data) {
            setUserName(res.data.user.name)
            setUserEmail(res.data.user.email)
            setUserInitials(initials(res.data.user.name))
            setRoles(res.data.user.roles)
            setAvatarColour(res.data.user.avatarColour)
            setWebBackgroundColour(res.data.user.company.backgroundColour)
        }
    }

    useEffect(() => {
        setTimeout(() => {
            getProfileDetailsFromBackend()
        })
    }, []);


    async function saveUserChipEdits() {
        const res = await api.users.updateMe(userName, avatarColour)
        setIsEditing(false);
        setUserInitials(initials(userName))
    }

    async function SaveBackgroundColourChanges() {
        const res = await api.users.admin.updateCompany({ backgroundColour: webBackgroundColour })
        setIsEditing(false)
    }

    useEffect(() => {
        // const body = document.body
        // if (body) {
        //     body.style.backgroundColor = webBackgroundColour
        // }
    }, [webBackgroundColour]);


    return (
        <main className="content profile-container">

            <div className="main-profile-holder box">
                <h1>Admin settings</h1>

                <div className="user-chip__info">
                    <p className="user-chip__initials" style={{ backgroundColor: avatarColour }}> {userInitials} </p>

                    <div className="user-chip__name">
                        <Input
                            id="name-update-input"
                            type="text"
                            labelText="Name"
                            value={userName}
                            onChange={setUserName}
                            readOnly={!isEditing}
                        />
                    </div>
                    {!isEditing && (
                        <button className="icon-button" onClick={() => setIsEditing(true)}>
                            <PencilIcon />
                        </button>
                    )}
                    {!!isEditing && (
                        <button className="link-button" onClick={saveUserChipEdits}> Save </button>
                    )}
                </div>
                <div>
                    {!!isEditing && (
                        <Select
                            style={{ maxWidth: '300px' }}
                            labelText="Select new avatar colour"
                            id="changeAvatarColour"
                            value={avatarColour}
                            onChange={setAvatarColour}
                            options={[
                                { label: 'Dark Blue', value: "#171D28" },
                                { label: 'Green', value: "#52A43A" },
                                { label: 'Royal Blue', value: "#32318A" },
                                { label: 'Sky Blue', value: "#2F70B5" },
                                { label: 'Red', value: "#C92F23" },
                                { label: 'Yellow', value: "#F3BA48" },
                                { label: 'Pink', value: "#D3488E" }
                            ]}
                        />
                    )}
                </div>
                <p className="user-chip__email"> {userEmail} </p>
                <OwnerGroupList
                    roles={roles} />
            </div>
        </main >
    )
}
export default ProfilePage;