import Papa from 'papaparse'
import { camel } from "case"

export const normaliseHeader = (input: string) => camel(input.trim())

export const normaliseKeys = (input: Record<string, any>) => {
  const keys = Object.keys(input)

  const objectToReturn: Record<string, any> = {}

  keys.forEach((key) => {
    const newKey = normaliseHeader(key)

    objectToReturn[newKey] = input[key]
  })

  return objectToReturn
}
interface Props {
  labelText: string
  onParseComplete: (data: any[], fileName: string) => void
}

const CsvUpload: React.FC<Props> = ({ onParseComplete, labelText }) => {

  const changeHandler = (event: any) => {
    if (!event.target.files[0]) {
      return
    }
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: 'greedy',
      complete: function (results) {
        // TODO: validation??
        // @ts-ignore
        onParseComplete(results.data.map((record: any[]) => normaliseKeys(record)), event.target.files[0].name)
        event.target.value = ''
      },
    });
  };

  return (
    <div>
      <label htmlFor="data-set-file">{labelText} (.csv)</label>
      <input
        id="data-set-file"
        type="file"
        name="file"
        accept=".csv"
        onChange={changeHandler}
        style={{display: 'block'}}
      />
    </div>
  )
}

export default CsvUpload