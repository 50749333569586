import { Fragment, useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as api from '../../api';
import { AppContext } from '../../App';
import { Process } from '../../api/process';
import Table, { HeaderComponent } from '../../components/table/Table';
import UserAdmin from './UserAdmin';
import ProcessCatogoryAdmin from '../../modules/admin/process-category-admin/ProcessCategoryAdmin';

interface Props {}

const AdminHome: React.FC<Props> = () => {
  const navigate = useNavigate();
  const ctx = useContext(AppContext);
  const [processes, setProcesses] = useState<Process[]>([]);

  useEffect(() => {
    if (!ctx.user.roles.includes('admin')) {
      navigate('/not-found');
    }

    setTimeout(() => {
      getProcesses();
    });
  }, []);

  const getProcesses = async () => {
    const res = await api.processes.admin.list();
    if (res.data) {
      setProcesses(res.data);
    }
  };

  const idColumn: HeaderComponent<Process> = (record) => {
    return {
      header: 'ID',
      element: (
        <Link className="link-button" to={`/admin/process/${record?.id}`}>
          {record?.id.slice(0, 6)}...
        </Link>
      ),
    };
  };

  const handleManageSubscriptionClick = async () => {
    const res = await api.subscription.createPortalSession();
    if (res.data) {
      window.location.href = res.data.redirectUrl
    }
  }

  return (
    <main className="content">
      <div className="box" style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h1>Admin Home</h1>
        <button onClick={handleManageSubscriptionClick} className='link-button'>Manage Subscription</button>
      </div>
      <div style={{ display: 'flex', columnGap: '32px' }}>
        <div className="box" style={{ width: '40%' }}>
          <div className="title-and-button-inline">
            <h2>Processes</h2>
            {!!processes.length && <Link className='button button--secondary button--small' to="/admin/process/new">New Process</Link>}
          </div>
          
          {!!processes.length && <Table data={processes} headers={[idColumn, 'name', 'published']} />}
          {!processes.length && (
            <Fragment>
              <p>It looks like you haven't created any processes yet.</p>
              <Link className='button' to="/admin/process/new">Create your first process</Link>
            </Fragment>
          )}
          <div style={{ marginTop: '64px' }}>
            <ProcessCatogoryAdmin />
          </div>
          
        </div>
        <div className="box" style={{ width: '60%' }}>
          <UserAdmin />
        </div>
      </div>
    </main>
  );
};

export default AdminHome;
