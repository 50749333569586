import { useLocation } from 'react-router-dom';
import * as api from '../api';
import queryString from 'query-string';
import { Fragment, useEffect, useState } from 'react';
import HelpLink from '../components/help-link/HelpLink';

interface Props {}

const CheckoutConfirmationPage: React.FC<Props> = () => {
  const [status, setStatus] = useState('');
  const location = useLocation();

  const getCheckoutSession = async () => {
    const query = queryString.parse(location.search);
    if (!query.session_id) {
      return;
    }

    const res = await api.subscription.getCheckoutSession(query.session_id as string);
    if (res.data) {
      setStatus(res.data.checkoutStatus);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      getCheckoutSession();
    });
  }, [location]);

  return (
    <main className="content">
      <div
        className="box"
        style={{
          marginTop: '10vh',
          width: '50vw',
          margin: 'auto',
          minWidth: '300px',
          minHeight: '500px',
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}
      >
        {!status && <p>Processing...</p>}
        {status === 'complete' && (
          <div>
            <h1 style={{ marginTop: '0' }}>Thank you for subscribing!</h1>
            <a href="/" className="button" style={{ margin: 'auto' }}>
              Proceed to home page
            </a>
            <HelpLink style={{ justifyContent: 'center', marginTop: '16px' }} to="https://operonix.com/support/get-started" text='Checkout this help article to get started with Operonix.'/>
          </div>
        )}
      </div>
    </main>
  );
};

export default CheckoutConfirmationPage;
