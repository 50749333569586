import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomePage from '../pages/HomePage';
import Nav from '../components/nav/Nav';
import ViewProcessPage from '../pages/ViewProcessPage';
import ViewCasePage from '../pages/ViewCasePage';
import AdminHome from '../pages/admin/AdminHome';
import ProcessAdmin from '../pages/admin/ProcessAdmin';
import ProfilePage from '../pages/ProfilePage';
import UserAdmin from '../pages/admin/UserAdmin';
import NewProcessPage from '../pages/admin/NewProcessPage';
import ProcessBuilder from '../pages/admin/ProcessBuilder';
import EditProcess from '../pages/admin/EditProcess';
import Search from '../modules/search/Search';
import { Fragment, useContext } from 'react';
import { AppContext } from '../App';
import CheckoutPage from '../pages/CheckoutPage';
import CheckoutConfirmationPage from '../pages/CheckoutConfirmationPage';
import SupervisorPage from '../pages/supervisor/SupervisorPage';
import AnalyticsPage from '../pages/Analytics';

type Props = {
  showSearch: boolean;
  setShowSearch: (showSearch: boolean) => void;
};

const AppRouter: React.FC<Props> = ({ showSearch, setShowSearch }) => {
  const ctx = useContext(AppContext);
  return (
    <BrowserRouter>
      <Nav hideLinks={ctx.user.company.subscriptionStatus !== 'ACTIVE'} />
      {ctx.user.company.subscriptionStatus === 'ACTIVE' && (
        <Fragment>
          <Search showSearch={showSearch} setShowSearch={setShowSearch} />
        </Fragment>
      )}

      <Routes>
        {ctx.user.company.subscriptionStatus === 'ACTIVE' && (
          <Fragment>
            <Route path="/" element={<HomePage />} />
            <Route path="/process/:id" element={<ViewProcessPage />} />
            <Route path="/case/:id" element={<ViewCasePage />} />
            <Route path="/admin" element={<AdminHome />} />
            <Route path="/supervisor" element={<SupervisorPage />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/admin/process/new" element={<NewProcessPage />} />
            <Route path="/admin/process/:id/old" element={<ProcessAdmin />} />
            <Route path="/admin/process/:id" element={<EditProcess />} />
            <Route path="/admin/process/:id/builder" element={<ProcessBuilder />} />
            <Route path="/admin/users" element={<UserAdmin />} />
            <Route path="/analytics" element={<AnalyticsPage />} />
          </Fragment>
        )}
        {ctx.user.company.subscriptionStatus !== 'ACTIVE' && (
          <Fragment>
            <Route path="*" element={<CheckoutPage />} />
          </Fragment>
        )}
        <Route path="/checkout-confirmation" element={<CheckoutConfirmationPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
