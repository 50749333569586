import { Fragment, useEffect, useState } from 'react';
import AnalyticsFiltersComponent, { emptyFilters } from '../modules/analytics/AnalyticsFilters';
import * as api from '../api';
import { AnalyticsFilters, AnalyticsResults, ProcessCompletion } from '../api/analytics';
import './Analytics.css';
import StatBox from '../modules/analytics/StatBox';
import { Bar, BarChart, LabelList, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { formatDate } from '../utils/date-utils';

interface Props {}

const AnalyticsPage: React.FC<Props> = () => {
  const [filters, setFilters] = useState<AnalyticsFilters>(emptyFilters);
  const [data, setData] = useState<AnalyticsResults>();

  const getData = async () => {
    const res = await api.analytics.get(filters);
    if (res.data) {
      setData(res.data);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      getData();
    });
  }, [filters]);

  const averageCompletionData = data?.averageCompletion.map((item) => ({
    process: item.process,
    averageCompletion: Math.round(Number(item.averageCompletion) * 100),
  }));

  const processCompletionGroupData: any[] = [];
  // for 10 loops, if data exists use that, if not use a default value
  for (let i = 0; i < 10; i++) {
    const completionGroup = data?.processCompletion.find(
      (item) => item.completionPercentageGroup === String(i + 1),
    );
    if (completionGroup) {
      processCompletionGroupData.push({
        completionPercentageGroup:
          String(Number(completionGroup.completionPercentageGroup) * 10) + '%',
        caseCount: Number(completionGroup.caseCount),
      });
      continue;
    }

    processCompletionGroupData.push({
      completionPercentageGroup: String((i + 1) * 10) + '%',
      caseCount: 0,
    });
  }

  return (
    <main className="content content--wide">
      <h1>Analytics</h1>
      <div className="analytics-page-container">
        <div className="box">
          <AnalyticsFiltersComponent
            filters={filters}
            setFilters={setFilters}
            availableFilters={data?.availableFilters}
          />
        </div>
        <div className="analytics-container">
          {!!data && (
            <Fragment>
              <div className="analytics-stats-container">
                <StatBox value={data.caseCount[0].count.toString() || '0'} label="Total cases" />
                <StatBox
                  value={data.caseCount[0].completedCases?.toString() || '0'}
                  label="Completed cases"
                />
                <StatBox
                  value={`${Math.round(data.controlOutcomes[0].controlPassRate * 100)}%`}
                  label="Control compliance"
                />
                <StatBox
                  value={`${Math.round(data.slaMet[0].slaMet * 100)}%`}
                  label="SLA Compliance"
                />
              </div>
              <div className="box" style={{ width: '100%', height: '400px' }}>
                <h3>Control compliance by week</h3>
                <ResponsiveContainer width="100%" height="85%">
                  <LineChart
                    data={data.controlOutcomeOverTime.map((dataPoint) => ({
                      week: formatDate(dataPoint.week, 'en-GB', 'UTC'),
                      controlPassRate: Math.round(dataPoint.controlPassRate * 100),
                    }))}
                    margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
                  >
                    <XAxis dataKey="week" label={{ value: 'Week start date', position: 'bottom' }}/>
                    <YAxis />
                    <Tooltip formatter={(value) => `${value}%`} />
                    <Line dataKey="controlPassRate" stroke="#52A43A" strokeWidth={3} dot={false} type="bump">
                      <LabelList dataKey="controlPassRate" position="top" formatter={(value: string) => `${value}%`}  />
                    </Line>
                  </LineChart>
                </ResponsiveContainer>
              </div>
              <div className='analytics-chart-container'>
                <div className="box" style={{ width: '100%', height: '400px' }}>
                <h3>Case Completion</h3>
                <ResponsiveContainer width="100%" height="85%">
                    <BarChart
                      data={processCompletionGroupData}
                      width={500}
                      height={300}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <XAxis dataKey="completionPercentageGroup" />
                      <YAxis label={{ value: 'Case count', position: 'left', angle: -90 }} tickCount={2} />
                      <Tooltip />
                      <Bar dataKey="caseCount" fill="#2F70B5" maxBarSize={50} />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
                <div className="box" style={{ width: '100%', height: '400px' }}>
                  <h3>Average completion % by process</h3>
                  <ResponsiveContainer width="100%" height="85%">
                    <BarChart
                      data={averageCompletionData}
                      width={500}
                      height={300}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                      layout="vertical"
                    >
                      <XAxis type="number" />
                      <YAxis dataKey="process" type="category" />
                      <Tooltip formatter={(value) => `${value}%`} />
                      <Bar dataKey="averageCompletion" fill="#2F70B5" maxBarSize={50} />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </Fragment>
          )}
        </div>
      </div>
    </main>
  );
};

export default AnalyticsPage;
