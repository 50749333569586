import { Fragment, useState } from 'react';
import './AutomateCaseCreation.css';
import { Process } from '../../../../api/process';
import CaseCreationIntegrationForm from './CaseCreationIntegrationForm';
import { Integration } from '../../../../api/integration/admin';
import Table, { TableHeader } from '../../../../components/table/Table';
import IntegrationIcon from '../../../../components/integration-icon/IntegrationIcon';

interface Props {
  process: Process;
  onSave: (process: Process) => void | Promise<void>;
}

const AutomateCaseCreation: React.FC<Props> = ({ process, onSave }) => {
  const [selectedIntegration, setSelectedIntegration] = useState<Integration>();

  const handleNewIntegrationSave = (newIntegration: Integration) => {
    onSave({
      ...process,
      integrations: [
        ...process.integrations,
        newIntegration,
      ]
    })
  }

  const tableHeaders: TableHeader<Integration>[] = [
    (integration) => ({
        header: 'id',
        element: <button className='link-button' onClick={() => setSelectedIntegration(integration)}>{integration?.id.slice(0,6)}</button>
    }),
    (integration) => {
        return {
        header: 'Type',
        element: <IntegrationIcon integrationType={integration?.type || 'API'} />
      }
    },
    'webhookId'
  ]
  return (
    <Fragment>
      <h2 style={{ margin: 0, marginBottom: '8px' }}>Automatically create cases</h2>
      <h3>Existing integrations</h3>
      <Table data={process.integrations} headers={tableHeaders} />
      {!process.integrations.length && (
        <small>You do not currently have any existing integrations</small>
      )}
      <CaseCreationIntegrationForm onSubmit={handleNewIntegrationSave} process={process} incomingIntegration={selectedIntegration} onCancel={() => setSelectedIntegration(undefined)} />
    </Fragment>
  );
};

export default AutomateCaseCreation;
