import { Fragment, useState } from 'react';
import { UploadedFile } from '../../api/file';

import Input from '../input/Input';
import './FileList.css';
import FileListItem from './FileListItem';

interface Props {
  files: UploadedFile[];
  refreshAuditLogs: () => void
}

const FileList: React.FC<Props> = ({ files, refreshAuditLogs }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const filteredFiles = files.filter((file) => {
    if (!searchTerm) {
      return true;
    }

    return file.name.toLowerCase().includes(searchTerm.toLowerCase());
  });

  

  return (
    <Fragment>
      <Input id="file-search" labelText="Search" type='search' value={searchTerm} onChange={setSearchTerm} />
      <div className="file-list">
        {filteredFiles.map((file) => {
          return (
            <FileListItem key={file.id} preloadedFile={file} refreshAuditLogs={refreshAuditLogs} />
          );
        })}
      </div>
    </Fragment>
  );
};

export default FileList;
