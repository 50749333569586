import { FormEvent, Fragment, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { Case } from '../api/case'
import * as api from '../api'
import CaseItems from '../modules/case/case-items/CaseItems'
import CaseUsers from '../modules/case/case-users/CaseUsers'
import './ViewCasePage.css'
import ProcessMap from '../modules/process-map/ProcessMap'
import SupportingInfo from '../modules/case/supporting-info/SupportingInfo'
import CaseStatus from '../modules/case/case-status/CaseStatus'
import { getActiveCaseStep } from '../modules/case/utils'
import CaseStep from '../modules/case/case-step/CaseStep'
import CaseDocuments from '../modules/case/case-documents/CaseDocuments'
import AuditList from '../components/audit-list/AuditList'
import CaseStepHistory from '../modules/case/case-step-history/CaseStepHistory'
import { AuditLog } from '../api/audit'
import CaseNotes from '../modules/case/case-notes/CaseNotes'
import CaseData from '../modules/case/case-data/CaseData'
import Input from '../components/input/Input'
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline'

interface Props {}

const ViewCasePage: React.FC<Props> = () => {
  const bottomPanelRef = useRef<HTMLDivElement>(null)
  const { id } = useParams()
  const [_case, setCase] = useState<Case | undefined>()
  const [editingNickname, setEditingNickname] = useState(false)
  const [auditLogs, setAuditLogs] = useState<AuditLog[]>([])

  const getAuditLogs = async () => {
    const res = await api.audit.list('CASE', id as string)
    if (res.data) {
      setAuditLogs(res.data)
    }
  }

  const getCase = async () => {
    const res = await api.cases.get(id as string)
    if (res.data) {
      setCase(res.data)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      getCase()
      getAuditLogs()
    })
  }, [id])

  const updateCase = (updates: Partial<Case>) => {
    // @ts-ignore
    setCase({ ..._case, ...updates })
    getAuditLogs()
  }

  const refreshAuditLogs = () => {
    getAuditLogs()
  }

  const handleNicknameSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const res = await api.cases.updateCaseNickname(id as string, _case?.nickname || '');
    if (!res.error) {
      setEditingNickname(false);
    }
  }

  // TODO: canEdit if status === DRAFT && userHasAccess

  let activeStep: { processStepId: string; id?: string } | undefined = getActiveCaseStep(_case)

  if (!activeStep && _case?.status === 'DRAFT') {
    activeStep = _case.process.steps[0] ? { processStepId: _case.process.steps[0].id } : undefined
  }

  const stepsWithCompletion: any = _case?.process.steps.map((processStep) => {
    const caseStep = _case.caseSteps.find((caseStep) => caseStep.processStepId === processStep.id)

    return {
      ...processStep,
      isCompleted: !!caseStep?.completedAt,
    }
  })

  return (
    <main className="content">
      {!!_case && (
        <Fragment>
          <div className="view-case__header">
            <div>
              <h2 className="view-case__process-name">{_case.process.name}</h2>
              {!editingNickname && (
                <Fragment>
                  <h1 className="view-case__code">{_case.nickname
                    ? <Fragment>{_case.nickname} <span className='view-case__code--small'>({_case.code})</span></Fragment>
                    : _case.code
                    }</h1>
                  <button
                    style={{ marginBottom: '8px' }}
                    className="link-button"
                    onClick={() => setEditingNickname(true)}
                  >
                    {_case.nickname ? 'Update case nickname' : 'Set case nickname'}
                  </button>
                </Fragment>
              )}
              {!!editingNickname && (
                <form className="view-case__nickname-form" onSubmit={handleNicknameSubmit}>
                  <Input
                    id="name"
                    small={true}
                    labelText="Nickname"
                    value={_case.nickname || ''}
                    onChange={(nickname) => setCase({ ..._case, nickname })}
                    inputClassName="process-details-form__name-input"
                  />
                  <button className="icon-button view-case__nickname-form-button" title="Save">
                    <CheckIcon />
                  </button>
                </form>
              )}
            </div>
            <CaseStatus _case={_case} updateCase={updateCase} />
          </div>
          <div className="view-case__contents">
            <div className="view-case__info">
              <div className="box">
                <CaseItems
                  caseId={id as string}
                  caseItems={_case.items}
                  updateCase={updateCase}
                  process={_case.process}
                  canAdd={true}
                />
              </div>
              <div className="box view-case__bottom-panel" ref={bottomPanelRef}>
                <Tabs>
                  <TabList>
                    {_case.status === 'ACTIVE' && <Tab>Current Step</Tab>}
                    {_case.status !== 'DRAFT' && <Tab>Step History</Tab>}
                    {!!_case.process.supportingData.length && <Tab>Supporting Info</Tab>}
                    <Tab>Process</Tab>
                    <Tab>Documents</Tab>
                  </TabList>
                  {_case.status === 'ACTIVE' && (
                    <TabPanel>
                      <CaseStep _case={_case} caseStepId={activeStep?.id as string} updateCase={updateCase} />
                    </TabPanel>
                  )}
                  {_case.status !== 'DRAFT' && (
                    <TabPanel>
                      <CaseStepHistory _case={_case} />
                    </TabPanel>
                  )}
                  {!!_case.process.supportingData.length && (
                    <TabPanel>
                      <SupportingInfo
                        caseId={id as string}
                        updateCase={updateCase}
                        supportingCaseData={_case.supportingData}
                        supportingDataFields={_case.process.supportingData}
                        editOnRender={_case.status === 'DRAFT'}
                      />
                    </TabPanel>
                  )}
                  <TabPanel>
                    <ProcessMap
                      processSteps={stepsWithCompletion}
                      parentRef={bottomPanelRef}
                      activeStepId={activeStep?.processStepId}
                    />
                  </TabPanel>
                  <TabPanel>
                    <CaseDocuments caseId={id as string} refreshAuditLogs={refreshAuditLogs} />
                  </TabPanel>
                </Tabs>
              </div>
            </div>

            <div className="box view-case__side-panel">
              <Tabs>
                <TabList>
                  <Tab>Case Users</Tab>
                  {_case.status !== 'DRAFT' && <Tab>Case Data</Tab>}
                  <Tab>Notes</Tab>
                  <Tab>Audit Log</Tab>
                </TabList>

                <TabPanel>
                  <CaseUsers
                    caseId={id as string}
                    caseUsersInProcess={_case.caseUsers}
                    ownerGroups={_case.process.ownerGroups}
                    canEdit={true}
                    updateCase={updateCase}
                  />
                </TabPanel>
                {_case.status !== 'DRAFT' && (
                  <TabPanel>
                    <CaseData _case={_case} />
                  </TabPanel>
                )}
                <TabPanel>
                  <CaseNotes caseId={id as string} />
                </TabPanel>
                <TabPanel>
                  <AuditList entityType="CASE" entityId={id as string} preloadedAuditLogs={auditLogs} />
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </Fragment>
      )}
    </main>
  )
}

export default ViewCasePage
