import { baseMethods,  } from '..'
import { User } from '../auth'
import * as admin from './admin'

export const list = async (search?: string) => {
  return baseMethods.get<{ users: User[] }>({
    path: '/users',
    params: { search },
    skipLoadingToast: true,
  })
}

export const me = async () => {
  return baseMethods.get<{ user: User }>({
    path: '/user/me'
  })
}

export const updateMe = async (name?: string, avatarColour?: string) => {
  return baseMethods.patch<{ user: User }>({
    path: '/user/me',
    body: { name, avatarColour }
  })
}

export { admin }