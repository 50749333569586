import { FormEvent, useEffect, useState } from 'react';
import { Case, CaseStep } from '../../../api/case';
import * as api from '../../../api';
import { PreviousStep, Step } from '../../../api/process';
import { isStepCompletable, stepHasSupportingDataCompleted } from '../utils';
import Select from '../../../components/input/Select';
import ItemFields from '../../../components/item-field-form/ItemFields';
import './CaseStepCompletion.css';
import Input from '../../../components/input/Input';
import LetterAutomation from '../automation/LetterAutomation';
import executeFormula from './utils';
import { trackEvent } from '../../..';
import CsvAutomation from '../automation/CsvAutomation';

interface Props {
  currentCaseStep: CaseStep;
  currentProcessStep: Step;
  nextProcessSteps: Step[];
  _case: Case;
  canCompleteCurrentStep: boolean;
  updateCase: (updates: Partial<Case>) => void;
}

const CaseStepCompletion: React.FC<Props> = ({
  _case,
  currentCaseStep,
  currentProcessStep,
  canCompleteCurrentStep,
  updateCase,
  nextProcessSteps,
}) => {
  const [stepDecision, setStepDecision] = useState(currentCaseStep.decision || '');
  const [supportingData, setSupportingData] = useState(currentCaseStep.supportingData);
  const [stepNotes, setStepNotes] = useState(currentCaseStep.notes || '');
  const [controlOutcome, setControlOutcome] = useState(currentCaseStep.controlOutcome || '');

  const nextProcessStepConnections: PreviousStep[] = []
  nextProcessSteps.forEach((nextProcessStep) => {
    nextProcessStep.previousSteps?.forEach((previousStepConnection) => {
      if (previousStepConnection.id === currentProcessStep.id) {
        nextProcessStepConnections.push(previousStepConnection)
      }
    });
  })

  const decisionOptions = nextProcessStepConnections.map((step) => ({
    label: step.label!,
    value: step.label!,
  }));

  const controlOutcomeOptions = [
    { label: 'Pass', value: 'PASS' },
    { label: 'Fail', value: 'FAIL' },
  ]

  useEffect(() => {
    if (currentProcessStep.autoDecisionFormula && !currentCaseStep.completedAt && !currentCaseStep.decision) {
      const decision = executeFormula(currentProcessStep.autoDecisionFormula, _case, currentCaseStep.id);
      const decisionOption = decisionOptions.find((option) => {
        const stringToMatch = decision ? 'yes' : 'no';
        return option.value.toLowerCase().includes(stringToMatch);
      });

      if (decisionOption) {
        setStepDecision(decisionOption.value);
        api.cases.updateCaseStep(_case.id, currentCaseStep.id, {
          supportingData,
          decision: decisionOption.value,
          notes: stepNotes,
        });
      }
    } else {
      setStepDecision(currentCaseStep.decision || '');
    }
    
    setSupportingData(currentCaseStep.supportingData);
    setStepNotes(currentCaseStep.notes || '');
  }, [currentCaseStep, currentProcessStep])

  const handleCancel = () => {
    setStepDecision(currentCaseStep.decision || '');
    setSupportingData(currentCaseStep.supportingData);
    setStepNotes(currentCaseStep.notes || '');
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (!canCompleteCurrentStep || _case.id === 'fake-case-id') {
      return;
    }

    const res = await api.cases.updateCaseStep(_case.id, currentCaseStep.id, {
      supportingData,
      decision: stepDecision,
      notes: stepNotes,
      controlOutcome,
    });

    if (res.data) {
      const updatedCase: Case = {
        ..._case,
        caseSteps: _case.caseSteps.map((step) => {
          if (step.id === currentCaseStep.id) {
            return res.data!;
          }
          return step;
        }),
      };

      updateCase(updatedCase);
    }
  };

  const { isCompletable: stepCompletable, errors } = isStepCompletable(currentCaseStep, currentProcessStep);
  const hasUnsavedChanges =
    stepDecision !== (currentCaseStep.decision || '') ||
    stepNotes !== (currentCaseStep.notes || '') ||
    controlOutcome !== (currentCaseStep.controlOutcome || '') ||
    JSON.stringify(supportingData) !== JSON.stringify(currentCaseStep.supportingData);

  const handleCompleteStepClick = async () => {
    if (!stepCompletable || _case.id === 'fake-case-id') {
      return;
    }

    const res = await api.cases.completeCaseStep(_case.id, currentCaseStep.id);
    if (res.data) {
      trackEvent('stepCompleted');
      setStepNotes('');
      setStepDecision('');
      setSupportingData({});
      updateCase(res.data);
    }
  };
  const letterAutomation = currentProcessStep.automation?.letter;
  const csvAutomation = currentProcessStep.automation?.csvDownload;

  return (
    <form className="case-step-completion" onSubmit={handleSubmit}>
      {currentProcessStep.type === 'DECISION' && (
        <Select
          id={`decision-${currentCaseStep.id}`}
          labelText="Decision*"
          onChange={setStepDecision}
          value={stepDecision}
          options={decisionOptions}
          readOnly={!canCompleteCurrentStep || !!currentProcessStep.autoDecisionFormula}
          helpText={currentProcessStep.autoDecisionFormula ? 'This decision is automated' : undefined}
        />
      )}
      {!!currentProcessStep.supportingData && (
        <ItemFields
          fields={currentProcessStep.supportingData}
          values={supportingData}
          setValues={setSupportingData}
          isEditing={canCompleteCurrentStep}
          entityId={_case.id}
        />
      )}
      {currentProcessStep.type === 'CONTROL' && (
        <Select
          id={`control-outcome-${currentCaseStep.id}`}
          labelText="Control Outcome*"
          onChange={setControlOutcome}
          value={controlOutcome}
          options={controlOutcomeOptions}
          readOnly={!canCompleteCurrentStep || !!currentProcessStep.autoDecisionFormula}
        />
      )}
      <Input
        id={`notes-${currentCaseStep.id}`}
        labelText="Notes specific to this step"
        type="textarea"
        value={stepNotes}
        onChange={setStepNotes}
        readOnly={!canCompleteCurrentStep}
      />
      {!!letterAutomation && (
        <LetterAutomation
          caseId={_case.id}
          currentCaseStepId={currentCaseStep.id}
          letterAutomation={letterAutomation}
          setSupportingData={setSupportingData}
          stepHasSupportingDataCompleted={stepHasSupportingDataCompleted(currentCaseStep, currentProcessStep)}
          supportingData={supportingData}
          updateCase={updateCase}
          readOnly={!canCompleteCurrentStep}
        />
      )}
      {!!csvAutomation && (
        <CsvAutomation
          caseId={_case.id}
          currentCaseStepId={currentCaseStep.id}
          csvAutomation={csvAutomation}
          setSupportingData={setSupportingData}
          supportingData={supportingData}
          updateCase={updateCase}
          readOnly={!canCompleteCurrentStep}
        />
      )}
      {!!canCompleteCurrentStep && (
        <div className="case-step-completion__actions">
          <div className="case-step-completion__form-actions">
            <button className="button" disabled={!hasUnsavedChanges}>
              Save
            </button>
            <button
              className="button button--secondary"
              disabled={!hasUnsavedChanges}
              type="button"
              onClick={handleCancel}
            >
              Cancel  
            </button>
          </div>
          {!!stepCompletable && !hasUnsavedChanges && (
            <button onClick={handleCompleteStepClick} className="button case-step-complete-button" type="button">
              Complete Step
            </button>
          )}
        </div>
      )}
      {!!errors.length && <small>To complete step: {errors.join(', ')}.</small>}
    </form>
  );
};

export default CaseStepCompletion;
