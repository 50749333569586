import { Step } from '../api/process';

export const getRightMostStep = (steps: Step[]) => {
  let x = 0;
  let y = 0
  steps.forEach((step) => {
    if ((step.mapPosition?.x || 0) > x) {
      x = step.mapPosition?.x || 0
      y = step.mapPosition?.y || 0
    }
  })

  return { x, y }
}
