import { camel } from "case";
import { Process, Step } from "../../../api/process";
import { getAllPreviousSteps } from "../../process-map/utils";

export const getAvalableFields = (process: Process, allSteps: Step[], currentStepId: string, requiresCurrentStepData?: boolean) => {
  const currentStep = allSteps.find((step) => step.id === currentStepId);
  const previousSteps = getAllPreviousSteps(allSteps, currentStep!, []);

  const availableFields: Record<string, string[]> = {
    [camel(process.itemName)]: [],
    case: [],
    step: [],
  };

  process.itemFields.forEach((itemField) => {
    availableFields[camel(process.itemName)].push(`${camel(process.itemName)}.${itemField.name}`);
  });

  process.supportingData?.forEach((itemField) => {
    availableFields.case.push(`case.${itemField.name}`);
  });

  previousSteps.forEach((step) => {
    step.supportingData?.forEach((itemField) => {
      availableFields.case.push(`case.${itemField.name}`);
    });
  });

  if (requiresCurrentStepData) {
    currentStep?.supportingData?.forEach((itemField) => {
      availableFields.step.push(`step.${itemField.name}`);
    });
  }

  return availableFields;
}