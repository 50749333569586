interface Props {
    roles: string[]
}

const OwnerGroupList: React.FC<Props> = ({ roles }) => {

    return (
        <div className="owner-groups-display">
            <h3 className="owner-groups-header"> Owner Groups </h3>
            <ul>  {roles.map((role) => {
                return <li key={role} > {role} </li>
            })} </ul>
        </div>
    )
}

export default OwnerGroupList