import { TrashIcon } from '@heroicons/react/24/outline';
import { DataMapping } from '../../../../api/integration/admin';
import Input from '../../../../components/input/Input'
import Select from '../../../../components/input/Select';
import './DataMappingInput.css';

interface Props {
  dataMapping: DataMapping
  onChange: (dataMapping: DataMapping) => void;
  onDelete?: (dataMapping: DataMapping) => void;
}

const DataMappingInput: React.FC<Props> = ({ dataMapping, onChange, onDelete }) => {

  let outputType = ''
  let outputField = ''

  const currentOutputParts = dataMapping.outputKey.split('.');
  if (currentOutputParts[0]) {
    outputType = currentOutputParts[0]
  }

  if (currentOutputParts[1]) {
    outputField = currentOutputParts[1]
  }
  return (
    <div className='data-mapping-input__container'>
      <Input
        id={`data-mapping-incoming-input-${dataMapping.id}`}
        labelText='Incoming Field'
        value={dataMapping.inputKey}
        onChange={(inputKey) => onChange({ ...dataMapping, inputKey })}
        placeholder='customer.firstName'
      />
      <span>to</span>
      <div className='data-mapping-input__field-container'>
      <Select
        id={`data-mapping-outgoing-type-input-${dataMapping.id}`}
        labelText='Output Base Field'
        value={outputType}
        onChange={(outputType) => onChange({ ...dataMapping, outputKey: `${outputType}.${outputField}` })}
        options={[{ label: 'supportingData', value: 'supportingData' }, { label: 'items', value: 'items' }]}
      />
      <Input
        id={`data-mapping-outgoing-field-input-${dataMapping.id}`}
        labelText='Output Field'
        value={outputField}
        onChange={(outputField) => onChange({ ...dataMapping, outputKey: `${outputType}.${outputField}` })}
      />
      </div>
      {!!onDelete && (
        <button className='icon-button' onClick={() => onDelete(dataMapping)}><TrashIcon /></button>
      )}
    </div>
  )
}

export default DataMappingInput