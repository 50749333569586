import Modal from '../../components/modal/Modal'
import * as api from '../../api'
import { SearchEntity, SearchResult } from '../../api/search'
import { FormEvent, useEffect, useState } from 'react'
import Select from '../../components/input/Select'
import Input from '../../components/input/Input'
import './Search.css'
import SearchResultItem from './SearchResultItem'
import { useLocation } from 'react-router-dom'
import { trackEvent } from '../..'

interface Props {
  showSearch: boolean
  setShowSearch: (showSearch: boolean) => void
}

const Search: React.FC<Props> = ({ showSearch, setShowSearch}) => {
  const location = useLocation();
  const [searchEntities, setSearchEntities] = useState<SearchEntity[]>([])
  const [selectedSearchEntity, setSelectedSearchEntity] = useState<string>('')
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [searchResults, setSearchResults] = useState<SearchResult[]>([])

  const getSearchEntities = async () => {
    const res = await api.search.getEntities()
    if (res.data) [
      setSearchEntities(res.data)
    ]
  }

  useEffect(() => {
    if (showSearch) {
      getSearchEntities()
    }
  }, [showSearch])


  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()
    const res = await api.search.get(selectedSearchEntity, searchTerm)
    if (res.data) {
      trackEvent('searchSubmitted')
      setSearchResults(res.data);
    }
  }

  const reset = () => {
    setSearchResults([])
    setSearchTerm('')
    setSelectedSearchEntity('')
  }

  useEffect(() => {
    if (!showSearch) {
      reset()
    }
  }, [showSearch])

  useEffect(() => {
      setShowSearch(false)
  }, [location])
  
  return (
    <Modal isOpen={showSearch} handleClose={() => setShowSearch(false)}>
      <h3>Search</h3>
      <form className='search__form' action="" onSubmit={handleSubmit}>
        <div className='search__input-container'>
          <Select
            id='search-entity'
            labelText='What do you want to search?'
            value={selectedSearchEntity}
            onChange={setSelectedSearchEntity}
            options={searchEntities}
            containerClassName='search__select-container'
          />
          <Input
            id='search-term'
            labelText='Search term'
            value={searchTerm}
            onChange={setSearchTerm}
            type="search"
            disabled={!selectedSearchEntity}
          />
        </div>
        <button className='button' disabled={!searchTerm}>Search</button>
      </form>
      {searchResults.map((searchResult) => {
        return (
          <SearchResultItem key={searchResult.case.id} searchResult={searchResult} />
        )
      })}
    </Modal>
  )
}

export default Search