import { ReactFlowProvider } from 'reactflow';
import * as api from '../../api';
import 'reactflow/dist/style.css';
import './ProcessMap.css';
import { useEffect, useRef } from 'react';
import ProcessMapInner, { ConnectableStep } from './ProcessMapInner';
import { MapPosition } from '../../api/process';

interface Props {
  processSteps: api.processes.Step[] & { isCompleted?: boolean };
  activeStepId?: string;
  parentRef?: React.RefObject<HTMLDivElement>;
  onStepSelect?: (stepId: string) => void;
  isEditable?: boolean;
  onStepUpdate?: (id: string, position: MapPosition) => void;
  onPreviousStepAdd?: (source: ConnectableStep, target: ConnectableStep) => void;
  onPreviousStepRemove?: (stepToUpdateId: string, previousStepIdToRemove: string) => void;
  onEdgeSelect?: (edge?: {stepId: string, previousStepId: string}) => void;
}

const ProcessMap: React.FC<Props> = ({
  processSteps,
  parentRef,
  activeStepId,
  onStepSelect,
  isEditable,
  onStepUpdate,
  onPreviousStepAdd,
  onPreviousStepRemove,
  onEdgeSelect,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (parentRef && parentRef.current && containerRef.current) {
      const parentWidth = parentRef.current.offsetWidth;
      const parentHeight = parentRef.current.offsetHeight;
      containerRef.current.style.width = `${parentWidth - 80}px`;
      containerRef.current.style.height = `${parentHeight - 76}px`;
    }
  }, [containerRef, parentRef]);

  return (
    <ReactFlowProvider>
      <div
        ref={containerRef}
        className="process-map__container"
        style={{ width: '100%', height: '100%' }}
      >
        <ProcessMapInner
          processSteps={processSteps}
          activeStepId={activeStepId}
          onStepSelect={onStepSelect}
          isEditable={isEditable}
          onStepUpdate={onStepUpdate}
          onPreviousStepAdd={onPreviousStepAdd}
          onPreviousStepRemove={onPreviousStepRemove}
          onEdgeSelect={onEdgeSelect}
        />
      </div>
    </ReactFlowProvider>
  );
};

export default ProcessMap;
