import './ProgressBar.css'

interface Props {
  value: number
}

const ProgressBar: React.FC<Props> = ({ value }) => {

  const displayPercentage = Math.round(value * 100);
  return (
    <div>
      <span>{displayPercentage}% complete</span>
      <progress className="progress" value={value} />
    </div>
  )
}

export default ProgressBar