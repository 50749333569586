import { Case } from '../../../api/case';
import ItemFields from '../../../components/item-field-form/ItemFields';
import { ItemField } from '../../../api/process';

interface Props {
  _case: Case;
}

const CaseData: React.FC<Props> = ({ _case }) => {
  let caseData: any = {};
  const fields: ItemField[] = [];
  _case.caseSteps.forEach((step) => {
    const processStep = _case.process.steps.find((processStep) => processStep.id === step.processStepId);
    if (processStep?.supportingData) {
      fields.push(...processStep.supportingData);
    }

    if (processStep?.automation?.letter) {
      fields.push({ name: processStep.automation.letter.name, type: 'file' });
    }

    if (processStep?.automation?.csvDownload) {
      fields.push({ name: processStep.automation.csvDownload.fileName, type: 'file' });
    }

    caseData = {
      ...caseData,
      ...step.supportingData,
    };
  });

  const fieldsToDisplay = fields.filter((field) => {
    return !!caseData[field.name];
  }).sort((a, b) => a.name.localeCompare(b.name));

  return (
    <div>
      <h3 style={{ margin: 0, marginBottom: '8px' }}>Case Data</h3>
      <p style={{ margin: 0, marginBottom: '32px' }}>This is all of the data recorded so far in this case</p>
      <ItemFields
        fields={fieldsToDisplay}
        values={caseData}
        entityId={_case.id}
        isEditing={false}
        setValues={() => {}}
      />
    </div>
  );
};

export default CaseData;
