import { FormEvent, useEffect, useState } from 'react';
import Input from '../../../components/input/Input';
import { User } from '../../../api/auth';
import * as api from '../../../api';
import TagSelect from '../../../components/input/TagSelect';

interface Props {
  user?: User;
  onComplete?: () => void;
}

const emptyForm: Pick<User, 'name' | 'email' | 'userGroups' | 'roles'> = {
  email: '',
  name: '',
  userGroups: [],
  roles: [],
};
const UserForm: React.FC<Props> = ({ user, onComplete }) => {
  const [ownerGroups, setOwnerGroups] = useState<string[]>([]);
  const [data, setData] = useState(user || emptyForm);

  const getOwnerGroups = async () => {
    const res = await api.processes.admin.list();
    if (res.data) {
      const ownerGroupSet = new Set<string>();
      res.data.forEach((process) => {
        process.ownerGroups.forEach((ownerGroup) => ownerGroupSet.add(ownerGroup))
      });

      setOwnerGroups(Array.from(ownerGroupSet.values()));
    }
  }

  useEffect(() => {
    getOwnerGroups()
  }, [])

  async function handleFormSubmit(event: FormEvent) {
    event.preventDefault();

    let res: api.ApiResponse<any>;
    if (!user) {
      res = await api.users.admin.create(data.name, data.email, data.roles, data.userGroups);
    } else {
      res = await api.users.admin.update(user.id, data);
    }

    if (!res.error && onComplete) {
      onComplete();
    }
  }

  return (
    <form onSubmit={handleFormSubmit}>
      <Input
        id="userName"
        type="text"
        labelText="Input users name"
        value={data.name}
        onChange={(name) => setData({ ...data, name })}
      />
      <Input
        id="email"
        type="text"
        labelText="Input users email"
        value={data.email}
        onChange={(email) => setData({ ...data, email })}
      />
      <TagSelect
        labelText='User Groups'
        availableTags={ownerGroups}
        selectedTags={data.userGroups}
        onChange={(userGroups) => setData({ ...data, userGroups })}
      />
      <TagSelect
        labelText='Roles'
        availableTags={['admin', 'supervisor']}
        selectedTags={data.roles}
        onChange={(roles) => setData({ ...data, roles: roles as User['roles'] })}
      />
      <button className="button" disabled={!data.email || !data.name}>
        {!!user ? 'Save' : 'Add new user'}
      </button>
    </form>
  );
};

export default UserForm;
