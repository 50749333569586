import { Fragment, useContext } from "react"
import * as chrono from 'chrono-node'
import Input from "../../../../components/input/Input"
import { formatDateTime } from "../../../../utils/date-utils";
import { AppContext } from "../../../../App";
import { utcToZonedTime} from "date-fns-tz";

interface Props {
  dueInSentence?: string
  updateDueInSentence: (dueInSentence: string) => void
  onSave: () => void;
  isCase?: boolean;
}

const EditDueDate: React.FC<Props> = ({ onSave, dueInSentence, updateDueInSentence, isCase }) => {
  const ctx = useContext(AppContext);
  const zonedDate = utcToZonedTime(new Date(), ctx.user.company.timezone);
  console.log(zonedDate)
  const parsedDate = chrono.parseDate(dueInSentence || '', { timezone: ctx.user.company.shortTimezone })
  let dateToDisplay = 'Invalid'
  if (parsedDate) {
    dateToDisplay = formatDateTime(parsedDate, ctx.user.company.locale, ctx.user.company.timezone)
  }

  let thingName = 'step'
  if (isCase) {
    thingName = 'case'
  }

  return (
    <Fragment>
      <h3>Add a due date</h3>
      <p>
        Using a sentence, describe when this step should be completed by (from the time the step is started). E.g.
        "Friday at 5pm, in 3 days, in 6 hours, etc"
      </p>
      <Input
        id="due-date"
        labelText={`When should this ${thingName} be due?`}
        value={dueInSentence || ''}
        onChange={updateDueInSentence}
        helpText={dueInSentence ? `If the ${thingName} started now, it would be due on: ${dateToDisplay}` : `No due date set for this ${thingName}`}
      />
      <button onClick={onSave} className='button'>Save</button>
    </Fragment>
  )
}

export default EditDueDate
