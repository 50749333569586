import { Fragment, useContext, useState } from 'react';
import { Case } from '../../../api/case';
import Table, { HeaderComponent } from '../../../components/table/Table';
import { formatDateTime } from '../../../utils/date-utils';
import Modal from '../../../components/modal/Modal';
import CaseStep from '../case-step/CaseStep';
import { AppContext } from '../../../App';

interface Props {
  _case: Case;
}

const CaseStepHistory: React.FC<Props> = ({ _case }) => {
  const ctx = useContext(AppContext);
  const [selectedStepId, setSelectedStepId] = useState<string | undefined>();

  const completedSteps = _case.caseSteps
    .filter((step) => step.completedAt !== null)
    .sort((a, b) => {
      return new Date(a.completedAt!).getTime() - new Date(b.completedAt!).getTime();
    })
    .map((caseStep) => {
      const processStep = _case.process.steps.find((step) => step.id === caseStep.processStepId);
      return {
        id: caseStep.id,
        stepName: processStep?.name,
        completedAt: caseStep.completedAt ? formatDateTime(caseStep.completedAt, ctx.user.company.locale, ctx.user.company.timezone) : '',
        decision: caseStep.decision,
        // notes: caseStep.notes,
        // supportingData: caseStep.supportingData
      };
    });

  const idHeader: HeaderComponent<(typeof completedSteps)[0]> = (step) => {
    return {
      header: 'id',
      element: (
        <button className="link-button" onClick={() => setSelectedStepId(step?.id)}>
          {step?.id.slice(0, 6)}
        </button>
      ),
    };
  };

  return (
    <Fragment>
      <Table data={completedSteps} search={true} headers={[idHeader, 'stepName', 'completedAt', 'decision']} />
      <Modal
        isOpen={!!selectedStepId}
        handleClose={() => setSelectedStepId(undefined)}
        modalStyles={{ maxWidth: '1000px' }}
      >
        {!!selectedStepId && <CaseStep _case={_case} caseStepId={selectedStepId} updateCase={() => {}} />}
      </Modal>
    </Fragment>
  );
};

export default CaseStepHistory;
