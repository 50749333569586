import { useContext, useEffect, useState } from "react"
import { SupervisorOustandingTask } from "../../api/case"
import * as api from '../../api'
import { AppContext } from "../../App"
import { Link, useNavigate } from "react-router-dom"
import Table, { HeaderComponent } from "../../components/table/Table"
import DueDate from "../../components/due-date/DueDate"
import ProgressBar from "../../components/progress-bar/ProgressBar"

interface Props {}

const SupervisorPage: React.FC<Props> = () => {
  const ctx = useContext(AppContext);
  const navigate = useNavigate()

  if (!ctx.user.roles.includes('supervisor')) {
    navigate('/');
  }

  const [outstandingTasks, setOutstandingTasks] = useState<SupervisorOustandingTask[]>([])

  const getOutstandingTasks = async () => {
    const res = await api.cases.getSupervisorOutstandingTasks()
    if (res.data) {
      setOutstandingTasks(res.data);
    }
  }

  useEffect(() => {
    setTimeout(() => {
      getOutstandingTasks()
    })
  }, [])

  const dueDateHeader: HeaderComponent<SupervisorOustandingTask> = (record) => {
    return {
      header: 'Due date',
      element: record?.dueDate ? <DueDate date={record.dueDate} /> : '',
    };
  };

  const caseIdHeader: HeaderComponent<SupervisorOustandingTask> = (record) => {
    return {
      element: <Link to={`/case/${record?.caseId}`}>{record?.nickname ? `${record?.nickname} (${record?.code})` : record?.code}</Link>,
      header: 'Case',
    };
  };

  const currentUsersHeader: HeaderComponent<SupervisorOustandingTask> = (record) => {
    const stepOwnerGroups = record?.ownerGroups.split(',') || []
    const currentUsers = record?.userRoles.filter((userRole) => {
      const userCaseRoles = userRole.roles.split(',');
      const userHasStepRoles = stepOwnerGroups.some((stepOwnerGroup) => userCaseRoles.includes(stepOwnerGroup));
      return userHasStepRoles
    })

    const userNames = currentUsers?.map((currentUser) => currentUser.user.name) || [];

    return {
      element: userNames.join(', '),
      header: 'Step owner',
    };
  };

  const progressHeader: HeaderComponent<SupervisorOustandingTask> = (record) => {
    return {
      element: <ProgressBar value={record?.completionPercentage || 0} />,
      header: 'Completion'
    }
  }

  const headers: (HeaderComponent<SupervisorOustandingTask> | keyof SupervisorOustandingTask)[] = [
    caseIdHeader,
    'processCategory',
    'processName',
    (record) => ({ header: 'Step Name', element: record?.processStepName || '' }),
    dueDateHeader,
    'stepStartedDate',
    currentUsersHeader,
    progressHeader
  ]

  return (
    <main className="content">
      <div className="box">
        <h1>Supervisor View</h1>
      </div>
      <div className="box">
        <h2>Outstanding Tasks</h2>
        <Table data={outstandingTasks} headers={headers} search={true} />
      </div>
    </main>
  )
}

export default SupervisorPage
