import { Case, CaseStep } from '../../../api/case';
import { Automation } from '../../../api/process';
import * as api from '../../../api';
import ItemFields from '../../../components/item-field-form/ItemFields';
import { Fragment, useState } from 'react';
import { BoltIcon } from '@heroicons/react/24/outline';
import './LetterAutomation.css';
import { trackEvent } from '../../..';

interface Props {
  updateCase: (updates: Partial<Case>) => void;
  supportingData: CaseStep['supportingData'];
  setSupportingData: (supportingData: CaseStep['supportingData']) => void;
  caseId: string;
  currentCaseStepId: string;
  csvAutomation: Automation['csvDownload'];
  readOnly: boolean;
}

const CsvAutomation: React.FC<Props> = ({
  updateCase,
  supportingData,
  setSupportingData,
  currentCaseStepId,
  caseId,
  csvAutomation,
  readOnly,
}) => {
  const [uploadOwn, setUploadOwn] = useState(false);
  if (!csvAutomation) {
    return <Fragment></Fragment>;
  }

  const handleGenerateCsvClick = async() => {
    const res = await api.cases.generateCsv(caseId, currentCaseStepId);
    if (res.data) {
      trackEvent('documentGenerated')
      updateCase(res.data)
    }
  }

  const hasFile = !!supportingData[csvAutomation.fileName.replace('.csv', '')];
  return (
    <div className='letter-automation'>
      <Fragment>
        <ItemFields
          fields={[{ name: csvAutomation.fileName.replace('.csv', ''), type: 'file', requiredOnCreate: true }]}
          values={supportingData}
          setValues={setSupportingData}
          isEditing={!readOnly && (hasFile || uploadOwn)}
          entityId={caseId}
        />

        {!readOnly && (
          <Fragment>
            <div className="letter-automation__actions">
              <button
                className='button button--secondary button--small'
                type="button"
                disabled={hasFile}
                onClick={handleGenerateCsvClick}
              >
                <BoltIcon />
                Generate CSV
              </button>
              {/* <button className='link-button' type="button" disabled={hasFile} onClick={() => setUploadOwn(!uploadOwn)}>
                Upload your own
              </button>{' '} */}
            </div>
          </Fragment>
        )}
      </Fragment>
    </div>
  );
};

export default CsvAutomation;
