import { Fragment, useEffect } from 'react'
import { v4 as uuid } from 'uuid'
import { Automation, Process, Step } from '../../../api/process'
import Input from '../../../components/input/Input'
import { DataMapping } from '../../../api/integration/admin'
import { getAvalableFields } from './utils'
import Select from '../../../components/input/Select'
import { TrashIcon } from '@heroicons/react/24/outline'

interface Props {
  process: Process
  stepId: string
  onChange: (stepAutomation: Automation) => void
  stepAutomation?: Automation
  allSteps: Step[]
  onSave: () => void
}

const ConfigureCsvDownload: React.FC<Props> = ({ process, stepId, onChange, stepAutomation, allSteps, onSave }) => {
  const handleDataMappingChange = (dataMappingToUpdate: DataMapping) => {
    const updatedDataMappings =
      stepAutomation?.csvDownload?.dataMapping?.map((dataMapping) => {
        if (dataMappingToUpdate.id === dataMapping.id) {
          return dataMappingToUpdate
        }

        return dataMapping
      }) || []

    onChange({
      ...stepAutomation,
      csvDownload: {
        fileName: stepAutomation?.csvDownload?.fileName || '',
        dataMapping: updatedDataMappings,
      },
      type: stepAutomation?.type || 'CSV_DOWNLOAD',
    })
  }

  const handleAddDataMapping = () => {
    const newDataMapping: DataMapping = {
      id: uuid(),
      inputKey: '',
      outputKey: '',
    }

    const dataMappings = [...(stepAutomation?.csvDownload?.dataMapping || []), newDataMapping]

    onChange({
      ...stepAutomation,
      csvDownload: {
        fileName: stepAutomation?.csvDownload?.fileName || '',
        dataMapping: dataMappings,
      },
      type: stepAutomation?.type || 'CSV_DOWNLOAD',
    })
  }

  const handleDataMappingDelete = (dataMappingToDelete: DataMapping) => {
    const updatedDataMappings =
      stepAutomation?.csvDownload?.dataMapping?.filter((dataMapping) => dataMapping.id !== dataMappingToDelete.id) || []
    onChange({
      ...stepAutomation,
      csvDownload: {
        fileName: stepAutomation?.csvDownload?.fileName || '',
        dataMapping: updatedDataMappings,
      },
      type: stepAutomation?.type || 'CSV_DOWNLOAD',
    })
  }

  useEffect(() => {
    if (!stepAutomation?.csvDownload?.dataMapping?.length) {
      handleAddDataMapping()
    }
  })

  const availableFields = getAvalableFields(process, allSteps, stepId)

  const fieldSourceOptions = Object.keys(availableFields).map((source) => ({
    value: source,
    label: source,
  }))

  const allowSave = stepAutomation?.csvDownload?.dataMapping?.length && stepAutomation?.csvDownload?.fileName

  return (
    <Fragment>
      <h2>Configure automation</h2>
      <Input
        id="csv-download-name"
        labelText="File Name"
        value={stepAutomation?.csvDownload?.fileName || ''}
        onChange={(fileName) =>
          onChange({
            ...stepAutomation,
            csvDownload: {
              fileName,
              dataMapping: stepAutomation?.csvDownload?.dataMapping || [],
            },
            type: stepAutomation?.type || 'CSV_DOWNLOAD',
          })
        }
      />
      <h3>Column mappings</h3>
      {stepAutomation?.csvDownload?.dataMapping.map((dataMapping) => {
        let [caseFieldSource, caseFieldKey] = dataMapping.inputKey.split('.')

        const caseFieldKeyOptions =
          availableFields[caseFieldSource]?.map((field) => {
            const value = field.split('.').pop() || '';
            return {
              value,
              label: value,
            }
          }) || []

        return (
          <div
            key={dataMapping.id}
            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '8px' }}
          >
            <Select
              id={`data-mapping-incoming-input-source-${dataMapping.id}`}
              labelText="Source"
              value={caseFieldSource}
              options={fieldSourceOptions}
              onChange={(source) => handleDataMappingChange({ ...dataMapping, inputKey: `${source}.${caseFieldKey}` })}
              style={{ maxWidth: '200px' }}
            />
            <Select
              id={`data-mapping-incoming-input-key-${dataMapping.id}`}
              labelText="Field"
              value={caseFieldKey}
              options={caseFieldKeyOptions}
              onChange={(key) => handleDataMappingChange({ ...dataMapping, inputKey: `${caseFieldSource}.${key}` })}
              style={{ maxWidth: '250px' }}
            />
            <span>to</span>
            <Input
              id={`data-mapping-outgoing-input-${dataMapping.id}`}
              labelText="CSV Column"
              value={dataMapping.outputKey}
              onChange={(outputKey) => handleDataMappingChange({ ...dataMapping, outputKey })}
            />
            <button className='icon-button' onClick={() => handleDataMappingDelete(dataMapping)}><TrashIcon /></button>
          </div>
        )
      })}
      <button onClick={handleAddDataMapping} className="button button--secondary button--small">
        Add column mapping
      </button>

      <button style={{ marginTop: '32px' }} onClick={onSave} className='button' disabled={!allowSave}>Save</button>
    </Fragment>
  )
}

export default ConfigureCsvDownload
