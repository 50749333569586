import { FormEvent, Fragment, useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import * as api from '../../../../api'
import Input from '../../../../components/input/Input';
import DataMappingInput from './DataMappingInput';
import './CaseCreationIntegrationForm.css';
import { DataMapping, Integration } from '../../../../api/integration/admin';
import UserSelect from '../../../../components/input/UserSelect';
import { Process } from '../../../../api/process';
import IntegrationIcon from '../../../../components/integration-icon/IntegrationIcon';
import { title } from 'case';
import { generateApiUrl } from './utils';
import HelpLink from '../../../../components/help-link/HelpLink';

interface Props {
  process: Process,
  incomingIntegration?: Integration;
  onSubmit: (integration: Integration) => void;
  onCancel: () => void;
}

const emptyIntegration: Partial<Integration> &
  Pick<
    Integration,
    'dataMappings' | 'uniqueCaseField' | 'webhookId' | 'webhookSecret' | 'caseOwnerUserId'
  > = {
  dataMappings: [{ id: uuid(), inputKey: '', outputKey: '' }],
  uniqueCaseField: { id: uuid(), inputKey: '', outputKey: '' },
  webhookId: '',
  webhookSecret: '',
  caseOwnerUserId: '',
};

const CaseCreationIntegrationForm: React.FC<Props> = ({ incomingIntegration, process, onCancel, onSubmit }) => {
  const [integration, setIntegration] = useState<Partial<Integration>>(
    incomingIntegration || emptyIntegration,
  );

  useEffect(() => {
    setIntegration(incomingIntegration || emptyIntegration)
  }, [incomingIntegration])

  useEffect(() => {
    if (integration.type === 'API' && !integration.webhookId) {
      setIntegration({ ...integration, webhookId: uuid() })
    }

    if (integration.type === 'API' && !integration.webhookSecret) {
      setIntegration({ ...integration, webhookSecret: uuid() })
    }
  })

  const handleAddDataMapping = () => {
    const newDataMapping: DataMapping = {
      id: uuid(),
      inputKey: '',
      outputKey: '',
    };

    const dataMappings = [...(integration.dataMappings || []), newDataMapping];

    setIntegration({ ...integration, dataMappings });
  };

  const isFormValid = () => {
    if (!integration.dataMappings?.length) return false;
    if (!integration.uniqueCaseField?.inputKey || !integration.uniqueCaseField?.outputKey)
      return false;
    if (!integration.webhookId) return false;
    if (!integration.webhookSecret) return false;
    if (!integration.caseOwnerUserId) return false;

    return true;
  };

  const handleDataMappingChange = (dataMappingToUpdate: DataMapping) => {
    const updatedDataMappings = integration.dataMappings?.map((dataMapping) => {
      if (dataMappingToUpdate.id === dataMapping.id) {
        return dataMappingToUpdate;
      }

      return dataMapping;
    }) || [];

    setIntegration({ ...integration, dataMappings: updatedDataMappings });
  }

  const handleDataMappingDelete = (dataMappingToDelete: DataMapping) => {
    const updatedDataMappings = integration.dataMappings?.filter((dataMapping) => dataMapping.id !== dataMappingToDelete.id) || [];
    setIntegration({ ...integration, dataMappings: updatedDataMappings });
  }

  const handleFormSubmit = async (e: FormEvent) => {
    e.preventDefault();

    let res: api.ApiResponse<api.integration.admin.Integration>
    if (!incomingIntegration) {
      res = await api.integration.admin.create(process.id, integration)
    } else {
      res = await api.integration.admin.update(integration.id!, integration)
    }

    if (res.data) {
      onSubmit(res.data)
    }
  }

  const handleFormClear = () => {
    setIntegration(incomingIntegration || emptyIntegration)
    onCancel()
  }

  return (
    <Fragment>
      <div className="integration-form__header-container">
        <h3>{!incomingIntegration && `Create New ${title(integration.type === 'API' ? 'Custom' : integration.type || '')} `}Integration {integration.id?.slice(0, 6)}</h3>
        {!!integration.type && <span className='integration-form__sub-header'><IntegrationIcon integrationType={integration.type} /></span>}
      </div>
      <form onSubmit={handleFormSubmit} className="integration-form__inner-container">
        {!integration.type && (
          <div className="box-button__container">
            <button
              className="box-button"
              onClick={() => setIntegration({ ...integration, type: 'API' })}
            >
              <IntegrationIcon integrationType='API' />
              <span>Custom (API)</span>
            </button>
            <button
              className="box-button"
              onClick={() => setIntegration({ ...integration, type: 'SHOPIFY' })}
            >
              <IntegrationIcon integrationType='SHOPIFY' />
              <span>Shopify</span>
            </button>
          </div>
        )}
        {!!integration.type && (
          <Fragment>
            <div className="integration-form__left">
              <div className='integration-form__docs'>
                <HelpLink
                  to={`https://operonix.com/support/${integration.type.toLowerCase()}-integration`}
                  text={`${integration.type === 'API' ? 'Custom' : title(integration.type)} integration guide`}
                />
                <pre>
                  URL:<br />
                  {generateApiUrl(integration as Integration)}<br />
                  {integration.type === 'API' && <Fragment>(HTTP POST request)</Fragment>}

                  {integration.type === 'API' && (
                    <Fragment>
                      <br /><br />
                      Headers:<br />
                      x-operonix-webhook-secret: {integration.webhookSecret}
                        
                    </Fragment>
                  )}
                </pre>
              </div>
                
              {integration.type === 'SHOPIFY' && (
                <Fragment>
                  <Input
                    id="webhook-secret-key"
                    labelText="Webhook Signing Key"
                    value={integration.webhookSecret || ''}
                    onChange={(webhookSecret) => setIntegration({ ...integration, webhookSecret })}
                  />
                  <Input
                    id="webhook-id"
                    labelText="Your Store URL"
                    value={integration.webhookId || ''}
                    onChange={(webhookId) => setIntegration({ ...integration, webhookId })}
                  />
                </Fragment>
              )}
              <UserSelect
                id="case-owner-user-id"
                labelText="Case Owner User"
                userIdsToExclude={[]}
                onSelect={(caseOwnerUserId) => setIntegration({ ...integration, caseOwnerUserId })}
              />
              <div className='button__group'>
                <button className="button" disabled={!isFormValid()}>
                  Save
                </button>
                <button className='button button--secondary' type="button" onClick={handleFormClear}>{incomingIntegration ? 'Cancel' : 'Clear'}</button>
              </div>
            </div>
            <div className="integration-form__right">
              <h4>What field should represent a new case?</h4>
              <p>We won't create a case if a case containing this field exists</p>
              <DataMappingInput
                dataMapping={integration.uniqueCaseField!}
                onChange={(uniqueCaseField) => setIntegration({ ...integration, uniqueCaseField })}
              />
              <div className="integration-form__data-mapping">
                <h4>Map your data to new cases</h4>
                {integration.dataMappings?.map((dataMapping) => {
                  return (
                    <DataMappingInput
                      key={dataMapping.id}
                      dataMapping={dataMapping}
                      onChange={handleDataMappingChange}
                      onDelete={handleDataMappingDelete}
                    />
                  );
                })}
                <button
                  onClick={handleAddDataMapping}
                  className="button button--secondary button--small"
                >
                  Add data mapping
                </button>
              </div>
            </div>
          </Fragment>
        )}
      </form>
    </Fragment>
  );
};

export default CaseCreationIntegrationForm;
