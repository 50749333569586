import { Fragment, useState } from 'react';
import { Case, CaseUser } from '../../../api/case';
import { title } from 'case';
import CaseUserInput from './CaseUserInput';
import UserChip from '../../../components/user-chip/UserChip';
import { User } from '../../../api/auth';
import * as api from '../../../api';

interface Props {
  caseId: string;
  ownerGroups: string[];
  caseUsersInProcess: CaseUser[];
  canEdit?: boolean;
  updateCase: (updates: Partial<Case>) => void;
}

const CaseUsers: React.FC<Props> = ({ caseId, ownerGroups, caseUsersInProcess, canEdit, updateCase }) => {
  const handleAddCaseUser = (caseUser: CaseUser) => {
    let isNewCaseUser = true;
    const newCaseUsers = caseUsersInProcess.map((existingCaseUser) => {
      if (existingCaseUser.user.id === caseUser.user.id) {
        isNewCaseUser = false;
        return caseUser;
      }
      return existingCaseUser;
    });

    if (isNewCaseUser) {
      newCaseUsers.push(caseUser);
    }

    updateCase({ caseUsers: newCaseUsers });
  };

  const handleDeleteUser = async (caseUser: CaseUser, role: string) => {
    const res = await api.cases.removeCaseUser(caseId, caseUser.user.id, role);
    if (res.data && res.data.caseUserRemoved) {
      const newCaseUsers = caseUsersInProcess.filter(
        (existingCaseUser) => existingCaseUser.user.id !== caseUser.user.id
      );
      updateCase({ caseUsers: newCaseUsers });
    } else if (res.data) {
      const newCaseUsers = caseUsersInProcess.map((existingCaseUser) => {
        if (existingCaseUser.id === caseUser.id) {
          return res.data!.caseUser;
        }

        return existingCaseUser;
      });

      updateCase({ caseUsers: newCaseUsers });
    }
  };
  return (
    <Fragment>
      <h3>Case Users</h3>
      {ownerGroups.map((ownerGroup) => {
        const caseUsersInGroup = caseUsersInProcess.filter((caseUser) => caseUser.roles.includes(ownerGroup));
        return (
          <div key={ownerGroup}>
            <h4>{ownerGroup.length > 2 ? title(ownerGroup) : ownerGroup}</h4>

            {caseUsersInGroup.map((caseUser) => {
              return (
                <UserChip
                  key={`${caseUser.id}_${ownerGroup}`}
                  user={caseUser.user as User}
                  onDelete={() => handleDeleteUser(caseUser, ownerGroup)}
                />
              );
            })}
            {!!canEdit && (
              <CaseUserInput
                onAddCaseUser={handleAddCaseUser}
                existingGroupCaseUsers={caseUsersInGroup}
                ownerGroup={ownerGroup}
                caseId={caseId}
              />
            )}
          </div>
        );
      })}
    </Fragment>
  );
};

export default CaseUsers;
