import { Fragment, useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { MapPosition, Process, Step } from '../../api/process';
import * as api from '../../api';
import ProcessMap from '../../modules/process-map/ProcessMap';
import { ConnectableStep } from '../../modules/process-map/ProcessMapInner';
import './ProcessBuilder.css';
import EditEdge from '../../modules/admin/process-builder/edit-edge/EditEdge';
import AddStep, { NewStep } from '../../modules/admin/process-builder/add-step/AddStep';
import EditStep from '../../modules/admin/process-builder/edit-step/EditStep';
import BuilderNav from '../../modules/admin/process-builder/builder-nav/BuilderNav';
import { getRightMostStep } from '../../utils/process';

interface Props {}

const ProcessBuilder: React.FC<Props> = () => {
  const previewRef = useRef<HTMLDivElement | null>(null);
  const { id } = useParams();
  const [process, setProcess] = useState<Process | null>(null);
  const [updateMade, setUpdateMade] = useState<boolean>(false);
  const [processSteps, setProcessSteps] = useState<Step[]>([]);
  const [builderMode, setBuilderMode] = useState<'DRAG' | 'SELECT'>('SELECT');
  const [selectedStepId, setSelectedStepId] = useState<string | undefined>(undefined);
  const [selectedEdge, setSelectedEdge] = useState<
    { stepId: string; previousStepId: string } | undefined
  >(undefined);

  const getProcess = async () => {
    const res = await api.processes.admin.get(id as string);
    if (res.data) {
      setProcess(res.data);
    }

    const stepsRes = await api.processes.admin.listSteps(id as string);
    if (stepsRes.data) {
      setProcessSteps(stepsRes.data);
    }
  };

  const handleStepPositionUpdate = (id: string, position: MapPosition) => {
    const updatedSteps = processSteps.map((step) => {
      if (step.id === id) {
        return {
          ...step,
          mapPosition: position,
        };
      }
      return step;
    });
    setProcessSteps(updatedSteps);
    setUpdateMade(true);
  };

  const handleStepUpdate = (updatedStep: Step) => {
    const updatedSteps = processSteps.map((step) => {
      if (step.id === updatedStep.id) {
        return updatedStep;
      }
      return step;
    });
    setProcessSteps(updatedSteps);
    setUpdateMade(true);
  };

  const handleSave = async () => {
    const res = await api.processes.admin.bulkUpsertSteps(id as string, processSteps);
    if (res.data) {
      setProcessSteps(res.data);
      setUpdateMade(false);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      getProcess();
    });
  }, [id]);

  const handlePreviousStepAdd = (source: ConnectableStep, target: ConnectableStep) => {
    const updatedSteps = processSteps.map((step) => {
      if (step.id === target.id) {
        return {
          ...step,
          previousSteps: [
            ...(step.previousSteps || []),
            { id: source.id, stepEdge: source.position, previousStepEdge: target.position },
          ],
        };
      }
      return step;
    });
    setProcessSteps(updatedSteps);
    setUpdateMade(true);
  };

  const handlePreviousStepRemove = (stepToUpdateId: string, previousStepIdToRemove: string) => {
    const updatedSteps = processSteps.map((step) => {
      if (step.id === stepToUpdateId) {
        return {
          ...step,
          previousSteps: (step.previousSteps || []).filter((s) => s.id !== previousStepIdToRemove),
        };
      }
      return step;
    });
    setProcessSteps(updatedSteps);
    setSelectedEdge(undefined);
    setUpdateMade(true);
  };

  const handleStepSelect = (stepId: string) => {
    setSelectedStepId(stepId);
  };

  const handleStepAdded = (step: NewStep) => {
    setBuilderMode('SELECT');

    const lastStepOnMapPosition = getRightMostStep(processSteps);
    const stepToAdd = {
      ...step,
      stepNumber: processSteps.length + 1,
      previousSteps: [],
      mapPosition: {
        x: lastStepOnMapPosition.x + 250,
        y: lastStepOnMapPosition.y
      }
    } as unknown as Step;
    setProcessSteps([...processSteps, stepToAdd]);
    setSelectedStepId(stepToAdd.id);
    
    setUpdateMade(true);

    setTimeout(() => {
      setBuilderMode('DRAG');
    }, 100)
  };

  return (
    <Fragment>
      <BuilderNav />

      <main className="content process-builder__container">
        <div className="box process-builder__side-bar">
          <h1>Process Builder</h1>
          <h2>{process?.name}</h2>
          {builderMode === 'SELECT' && (
            <Fragment>
              <p>Select a step or a connection to edit it</p>
              <button className="link-button" onClick={() => setBuilderMode('DRAG')}>
                Switch to drag and connect mode
              </button>
            </Fragment>
          )}
          {builderMode === 'DRAG' && (
            <Fragment>
              <p>Drag and drop steps to reposition them</p>
              <button className="link-button" onClick={() => setBuilderMode('SELECT')}>
                Switch to select mode
              </button>
            </Fragment>
          )}
          {!!selectedEdge && (
            <EditEdge
              allSteps={processSteps}
              previousStepId={selectedEdge.previousStepId}
              stepId={selectedEdge.stepId}
              updateStep={handleStepUpdate}
              handlePreviousStepRemove={handlePreviousStepRemove}
            />
          )}
          {!selectedEdge && !selectedStepId && (
            <AddStep onStepAdd={handleStepAdded} ownerGroups={process?.ownerGroups || []} />
          )}
          {!!selectedStepId && !!process && (
            <Fragment>
              <EditStep
                process={process}
                selectedStepId={selectedStepId}
                allSteps={processSteps}
                handleStepUpdate={handleStepUpdate}
              />
              <button style={{ marginTop: '32px'}} onClick={() => setSelectedStepId(undefined)} className="link-button">
                unselect step
              </button>
            </Fragment>
          )}
        </div>
        <div ref={previewRef} className="box process-builder__map" style={{ minHeight: '70vh' }}>
          <ProcessMap
            processSteps={processSteps}
            parentRef={previewRef}
            isEditable={builderMode === 'DRAG'}
            onStepUpdate={handleStepPositionUpdate}
            onPreviousStepAdd={handlePreviousStepAdd}
            onPreviousStepRemove={handlePreviousStepRemove}
            onStepSelect={builderMode === 'SELECT' ? handleStepSelect : undefined}
            activeStepId={builderMode === 'SELECT' ? selectedStepId : undefined}
            onEdgeSelect={setSelectedEdge}
          />
          <div className="process-builder__save-container">
            {!!updateMade && (
              <small className="process-builder__save-warning">
                Remember to save your changes!
              </small>
            )}
            <button
              disabled={!updateMade}
              className="button process-builder__save-button"
              onClick={handleSave}
            >
              Save
            </button>
          </div>
        </div>
      </main>
    </Fragment>
  );
};

export default ProcessBuilder;
