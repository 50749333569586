import { useNavigate } from "react-router-dom"
import * as api from "../api"
import './ViewProcessSideBar.css'

interface Props {
  process: api.processes.Process
}

const ViewProcessSideBar: React.FC<Props> = ({ process }) => {
  const navigate = useNavigate()
  const handleCreateCase = async () => {

    const res = await api.cases.create(process.id)
    if (res.data) {
      navigate(`/case/${res.data.id}`)
    }
  }

  return (
    <div className="process-side-bar">
      <h1 className="process-side-bar__title">{process.name}</h1>
      {!!process.description && (
        <p className="process-side-bar__description">{process.description}</p>
      )}
      <button onClick={handleCreateCase} className="button">Create New Case</button>
    </div>
  )
}

export default ViewProcessSideBar