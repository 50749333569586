import initials from 'initials';
import { User } from '../../api/auth';
import './UserChip.css';
import { TrashIcon } from '@heroicons/react/24/outline';

interface Props {
  user: User;
  small?: boolean;
  onDelete?: (user: User) => void | Promise<void>;
}

const UserChip: React.FC<Props> = ({ user, onDelete, small }) => {
  let initialsToDisplay = initials(user.email);
  user.avatarColour;
  if (user.name.split(' ').length > 1) {
    initialsToDisplay = initials(user.name);
  }

  return (
    <div className={`user-chip ${small ? 'user-chip--small' : ''}`}>
      <div className='user-chip__info'>
        <span className="user-chip__initials" style={{ backgroundColor: user.avatarColour }}>{initialsToDisplay.toUpperCase()}</span>
        <p className="user-chip__name">
          {user.name}
          <span className="user-chip__email"> ({user.email})</span>
        </p>
      </div>

      {!!onDelete && (
        <button className="icon-button" onClick={() => onDelete(user)}>
          <TrashIcon />
        </button>
      )}
    </div>
  );
};

export default UserChip;
