import { ReactGrid, Column, Row, DefaultCellTypes, ReactGridProps, Cell, TextCell, NumberCell } from "@silevis/reactgrid";
import { v4 as uuid } from 'uuid'
import { Case, CaseItem } from '../../../api/case';
import { ItemField, Process } from '../../../api/process';
import { title } from 'case';
import { Fragment, useEffect, useState } from 'react';
import { PlusIcon } from "@heroicons/react/24/outline";

interface Props {
  caseId: string;
  caseItems: CaseItem[];
  process: Process;
  canEdit?: boolean;
  canAdd: boolean;
  onSave: (caseItems: CaseItem[]) => Promise<void> | void
}

const convertFieldToCell = (field: ItemField, value: string): DefaultCellTypes => {
  if (field.type === 'number') {
    return {
      type: 'number',
      value: parseInt(value),
    }
  }

  if (field.type === 'date') {
    return {
      type: 'date',
      date: new Date(value),
    }
  }

  return {
    type: 'text',
    text: value || '',
  }
}

const CaseItemSpreadsheetView: React.FC<Props> = ({
  caseId,
  process,
  caseItems,
  onSave,
  canAdd,
}) => {

  const [items, setItems] = useState<CaseItem[]>(caseItems)

  const columns: Column[] = process.itemFields.map((field) => {
    return {
      columnId: field.name,
      width: 150,
    }
  });


  const headerRow: Row = {
    rowId: "header",
    cells: process.itemFields.map((field) => {
      return {
        type: 'header',
        text: title(field.name),
      }
    })
  };

  const rows: Row[] = [
    headerRow,
    ...items.map((item): Row => {
      return {
        rowId: item.id,
        cells: process.itemFields.map((field) => {
          return convertFieldToCell(field, item.data[field.name]);
        })
      }
    })
  ]

  useEffect(() => {
    setItems(caseItems);
  }, [caseItems])

  const handleUpdates: ReactGridProps['onCellsChanged'] = (changes) => {
    const updatedItems = [...items]
    changes.forEach(({ rowId, columnId, newCell }) => {
      const itemToUpdate = updatedItems.find((item) => item.id === rowId)
      let value: number | string | Date | undefined = (newCell as NumberCell).value
      if (newCell.type === 'text') {
        value = newCell.text
      }

      if (newCell.type === 'date') {
        value = newCell.date
      }
      if (itemToUpdate) {
        itemToUpdate.data[columnId] = value
      }
    })

    setItems(updatedItems)
  }

  const handleSave = async () => {
    onSave(items);
  }

  const handleCancel = () => {
    setItems(caseItems);
  }

  const addNewRow = () => {
    const item: any = {
      id: uuid(),
      data: {}
    }

    process.itemFields.forEach((itemField) => {
      item.data[itemField.name] = null
    })

    setItems([...items, item])
  }

  return (
    <div>
      <ReactGrid rows={rows} columns={columns} onCellsChanged={handleUpdates} />
      {canAdd && (
        <Fragment>
          <button onClick={addNewRow} className="icon-button" title="add row"><PlusIcon /> </button>
          <div className="button__group" style={{ marginTop: '16px' }}>
            <button className="button button--small" onClick={handleSave}>Save</button>
            <button className="button button--small button--secondary" onClick={handleCancel}>Cancel</button>
          </div>
        </Fragment>
      )}
      
    </div>
  )
}

export default CaseItemSpreadsheetView