import { FormEvent, Fragment, useState } from 'react';
import Input from '../../../components/input/Input';
import * as api from '../../../api';
import { CheckCircleIcon, CheckIcon } from '@heroicons/react/24/outline';

interface Props {
  processId: string;
  processStepId?: string;
}

const RequestAutomationForm: React.FC<Props> = ({ processId, processStepId }) => {
  const [details, setDetails] = useState('');
  const [requestSubmitted, setRequestSubmitted] = useState(false);

  const handleFormSubmission = async (e: FormEvent) => {
    e.preventDefault();
    const res = await api.integration.admin.request(processId, details, processStepId);
    if (!res.error) {
      setRequestSubmitted(true);
    }
  };

  return (
    <Fragment>
      <h3>Request a custom automation</h3>
      <p>
        We offer unlimited bespoke integrations with your Operonix subscription. Provide as much
        detail below with your request to automate this step and we will be in touch with next
        steps.
      </p>
      <p>Please include as much detail as possible, such as:</p>
      <ul>
        <li>Description of the step you want to automate</li>
        <li>Details and documentation of the 3rd party system system we need to interact with</li>
        <li>The data (and format) we need to send to the 3rd party system</li>
        <li>The data we need to receive and save to cases</li>
        <li>Any credentials we need to interact with the 3rd party system</li>
      </ul>
      <p>
        Once we receive your request we'll be in touch as soon as possible to (2 working days
        maximum). We'll do our best to complete your request, however it may be possible that we
        need more information, in which case we will contact you via email.
      </p>
      {!requestSubmitted && (
        <form onSubmit={handleFormSubmission}>
          <Input
            id="request-automation"
            labelText="Request details"
            type="textarea"
            value={details}
            onChange={setDetails}
          />
          <button className="button">Submit</button>
        </form>
      )}
      {!!requestSubmitted && (
        <p style={{ display: 'flex', alignItems: 'center' }}><CheckCircleIcon style={{ color: 'var(--success)', marginRight: '8px' }} /> Thank you for your request, we will be in touch with next steps!</p>
      )}
    </Fragment>
  );
};

export default RequestAutomationForm;
