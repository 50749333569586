import { baseMethods } from '.';
import { Case } from './case';

export type AnalyticsFilters = {
  startDate?: string;
  endDate?: string;
  processIds?: string[];
  processCategories?: string[];
  caseStatuses?: Case['status'][];
};

export type ControlOutcome = {
  controlPassRate: number;
  count: string;
};

export type SlaMet = {
  slaMet: number;
  count: string;
};

export type AverageCompletion = {
  process: string;
  averageCompletion: string;
};

export type ProcessCompletion = {
  caseCount: string;
  completionPercentageGroup: string;
};

export type CaseCount = {
  completedCases: string;
  count: string;
}

export type ControlOutcomeOverTime = {
  controlPassRate: number;
  week: string;
};

export type AnalyticsResults = {
  controlOutcomes: ControlOutcome[];
  controlOutcomeOverTime: ControlOutcomeOverTime[];
  slaMet: SlaMet[];
  averageCompletion: AverageCompletion[];
  processCompletion: ProcessCompletion[];
  caseCount: CaseCount[];
  availableFilters: AnalyticsFilters;
};

export const get = (filters: AnalyticsFilters) => {
  return baseMethods.get<AnalyticsResults>({
    path: '/analytics',
    params: filters,
  })
}
