import { useState } from 'react';
import './GuidanceNote.css';
import { LightBulbIcon } from '@heroicons/react/24/outline';
import Markdown from 'react-markdown';

const SHOW_LESS_LENGTH = 300;

interface Props {
  note: string;
}

// Regular expressions to identify links and images
const linkRegex = /\[([^\]]+)\]\((https?:\/\/[^\s]+)\)/g;
const imageRegex = /!\[([^\]]*)\]\((https?:\/\/[^\s]+)\)/g;



const GuidanceNote: React.FC<Props> = ({ note }) => {
  const [showMore, setShowMore] = useState(false);

  let textToDisplay = note;
  const needsShowMore = note.length > SHOW_LESS_LENGTH;
  if (needsShowMore && !showMore) {
    // Remove links and images from the markdown
    textToDisplay = note.replace(linkRegex, '[LINK - expand to show]').replace(imageRegex, '[IMAGE - expand to see]').slice(0, SHOW_LESS_LENGTH) + '...';

  }

  if (showMore) {
    textToDisplay = note;
  }
  return (
    <div className="guidance-note__container">
      <div>
        <LightBulbIcon />
      </div>
      <div>
        <h4 className="guidance-note__title">Guidance Note</h4>
        <div className="guidance-note__text">
          <Markdown>
            {textToDisplay}
          </Markdown>
          {needsShowMore && (
            <button className="link-button" onClick={() => setShowMore(!showMore)}>
              {showMore ? 'see less' : 'see more'}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default GuidanceNote;
