import { Link } from 'react-router-dom';
import { jwtLocalStorageKey } from '../../api/auth';
import styles from './Nav.module.css';
import {
  ArrowLeftOnRectangleIcon,
  ClipboardIcon,
  CogIcon,
  HomeIcon,
  MagnifyingGlassIcon,
  QuestionMarkCircleIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import { Fragment, useContext } from 'react';
import { AppContext } from '../../App';
import { getBrand } from '../../utils/brand';
import { trackEvent } from '../..';

interface Props {
  hideLinks?: boolean;
}

const Nav: React.FC<Props> = ({ hideLinks }) => {
  const ctx = useContext(AppContext);

  const logout = () => {
    window.localStorage.removeItem(jwtLocalStorageKey);
    window.location.reload();
  };

  const logoUrl = getBrand() === 'operonix' ? '/operonix-logo.jpg' : '/logo.png';

  const handleSearchClick = () => {
    ctx.setShowSearch(true);
    trackEvent('searchOpened');
  };

  return (
    <nav className={`content ${styles['nav']}`}>
      <div className={`box ${styles['nav__left']}`}>
        <span className={styles['nav__company-name']}>{ctx.user.company.name}</span>
        <div className={styles['nav__left-links']}>
          {!hideLinks && (
            <Fragment>
              <button className={styles['nav__button']} title="Search" onClick={handleSearchClick}>
                <MagnifyingGlassIcon />
              </button>
              <Link className={styles['nav__button']} to="/" title="Home">
                <HomeIcon />
              </Link>
              {ctx.user.roles.includes('supervisor') && (
                <Link className={styles['nav__button']} to="/supervisor" title="Supervisor">
                  <ClipboardIcon />
                </Link>
              )}
              {ctx.user.roles.includes('admin') && (
                <Link className={styles['nav__button']} to="/admin" title="Admin">
                  <CogIcon />
                </Link>
              )}

              <Link className={styles['nav__button']} title="Profile" to="/profile">
                <UserIcon />
              </Link>
              <a className={styles['nav__button']} title="Help" target='_blank' href="https://operonix.com/support">
                <QuestionMarkCircleIcon />
              </a>
            </Fragment>
          )}
          <button className={styles['nav__button']} title="Logout" onClick={logout}>
            <ArrowLeftOnRectangleIcon />
          </button>
        </div>
      </div>

      <img className={`${styles['nav__right']}`} src={logoUrl} />
    </nav>
  );
};

export default Nav;
