import { lower, title } from 'case';
import { Case } from '../../../api/case';
import * as api from '../../../api'
import { CheckCircleIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import './CaseStatus.css';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../App';
import { validateItemFields } from '../utils';
import InfoBanner from '../../../components/info-banner/InfoBanner';
import ProgressBar from '../../../components/progress-bar/ProgressBar';
import DueDate from '../../../components/due-date/DueDate';

interface Props {
  _case: Case;
  updateCase: (updates: Partial<Case>) => void;
}

const calculatePublishRequirements = (_case: Case) => {

  const publishRequirements = [];

  if (!_case.items.length) {
    publishRequirements.push(`at least one ${lower(_case.process.itemName)}`);
  }

  const supportingDataFields = _case.process.supportingData;
  let hasSupportingData = validateItemFields(supportingDataFields, _case.supportingData)
  if (!hasSupportingData) {
    publishRequirements.push(`required supporting data completed`);
  }

  return {
    canPublish: !publishRequirements.length && _case.status === 'DRAFT',
    publishRequirements
  };
};

const CaseStatus: React.FC<Props> = ({ _case, updateCase }) => {
  const ctx = useContext(AppContext)
  const [publishInfo, setPublishInfo] = useState<{ canPublish: boolean, publishRequirements: string[] }>({ canPublish: true, publishRequirements: [] });
  

  useEffect(() => {
    
    const { canPublish, publishRequirements } = calculatePublishRequirements(_case);
    if (!publishInfo.canPublish && canPublish === true) {
      ctx.setToast({ message: `Case ready to publish!`, type: 'success', clearAfterMs: 3000 })
    }
    setPublishInfo({ canPublish, publishRequirements })
  }, [_case])

  const publishCase = async () => {
    const res = await api.cases.publish(_case.id)
    if (res.data) {
      updateCase(res.data)
      ctx.setToast({ message: `Case published!`, type: 'success', clearAfterMs: 3000 })
    }
  }


  const { canPublish, publishRequirements } = publishInfo;
  return (
    <div className="case-status box">
      <div className='case-status__inner'>
        <p className="case-status__status">
          {title(_case.status)}
        </p>
        {_case.status === 'DRAFT' && <button disabled={!canPublish} className='button' onClick={publishCase}>Publish</button>}
      </div>
      <ProgressBar value={_case.status === 'COMPLETED' ? 1 : _case.completionPercentage} />
      {!!_case.dueDate && (
        <div style={{ marginTop: '16px', display: 'flex', justifyContent: 'flex-end' }}>  
          <DueDate date={_case.dueDate} />
        </div>
        
      )}
      {!!publishRequirements.length && (<InfoBanner variant='warning' icon={<ExclamationTriangleIcon />} message={`Case must have ${lower(publishRequirements.join(', '))}`} />)}
      {(!publishRequirements.length && _case.status === 'DRAFT') && (<InfoBanner variant='success' icon={<CheckCircleIcon />} message="Case ready to publish!" />)}
    </div>
  );
};

export default CaseStatus;
