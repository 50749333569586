import { FormEvent, useEffect, useState } from 'react';
import ItemFieldConfigList from '../../configure-item-field/ItemFieldConfigList';
import { Process } from '../../../../api/process';

interface Props {
  process: Process
  onSave: (process: Process) => void | Promise<void>;
}

const SupportingDataConfig: React.FC<Props> = ({ process, onSave}) => {
  const [formData, setFormData] = useState<Process>(process);
  
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    onSave(formData);
  }

  useEffect(() => {
    setFormData(process);
  }, [process])

  return (
    <form onSubmit={handleSubmit}>
      <h2 style={{ marginBottom: '8px' }}>Supporting Data</h2>
      <p style={{ margin: 0, marginBottom: '16px' }}>
        This is an optional set of fields to capture supplemental data that doesn't make sense to
        record inside an item.
      </p>
      <ItemFieldConfigList
        itemFields={formData.supportingData || []}
        labelText="Supporting Data Fields"
        onChange={(supportingData) => setFormData({ ...formData, supportingData })}
      />
      <button className='button'>Save</button>
    </form>
  );
};

export default SupportingDataConfig;
