import './InfoBanner.css'

interface Props {
  icon: JSX.Element
  message: string | JSX.Element
  variant?: 'warning' | 'success'
}

const InfoBanner: React.FC<Props> = ({ icon, message, variant}) => {

  return (
    <p className={`info-banner ${variant ? `info-banner--${variant}` : ''}`}>{icon} {message}</p>
  )
}

export default InfoBanner