import { baseMethods } from '..'

export const createCheckoutSession = async () => {
  return baseMethods.post<{ clientSecret: string }>({
    path: '/subscription/checkout-session',
  })
}

export const getCheckoutSession = async (sessionId: string) => {
  return baseMethods.get<{ checkoutStatus: string }>({
    path: `/subscription/checkout-session/${sessionId}`
  })
}

export const createPortalSession = async () => {
  return baseMethods.post<{ redirectUrl: string }>({
    path: '/subscription/portal-session',
  })
}