import { ArrowRightIcon } from '@heroicons/react/24/outline';
import { Step } from '../../../api/process';
import DecisionStep from '../../../components/steps/DecisionStep';
import StandardStep from '../../../components/steps/StandardStep';
import './NextSteps.css';

interface Props {
  currentStepId: string;
  steps: Step[];
}

const NextSteps: React.FC<Props> = ({ steps, currentStepId }) => {
  return (
    <div className="next-steps__container">
      <p className="next-steps__title">Next Step{steps.length > 1 ? 's' : ''}</p>
      {!steps.length && <p>This is the final step in the process</p>}
      {steps.map((step) => {
        let previousStepLabel = '';
        const previousStepConnection = step.previousSteps?.find((previousStep) => previousStep.id === currentStepId);
        if (previousStepConnection) {
          previousStepLabel = previousStepConnection.label || '';
        }
        return (
          <div key={step.id} className="next-steps__step">
            <div className="next-steps__previous-step-label">
              {!!previousStepLabel && <span>{previousStepLabel}</span>}
              <ArrowRightIcon />
            </div>
            {step.type === 'DECISION' && <DecisionStep step={step} />}
            {step.type !== 'DECISION' && <StandardStep step={step} />}
          </div>
        );
      })}
    </div>
  );
};

export default NextSteps;
