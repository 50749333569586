import { ItemField } from "../../api/process";

export const validateValue = (field: ItemField, value: any) => {
  if (!value) {
    return true;
  }

  if (field.maxLength && value.length > field.maxLength) {
    return false
  }

  if (field.minLength && value.length < field.minLength) {
    return false
  }

  return true;
}