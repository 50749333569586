import { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';
import * as api from '../api';

const stripeKey =
  process.env.REACT_APP_STRIPE_KEY || 'pk_test_51MQ8P8BjmnjxuZRSY08T4grXQEIoZ6uhh4ktr5fmSbngIAUaLVmXv765J0m9cE3qIRItptkD8cj7gQKbnoQHMP4Q00VckJoSKT';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// This is your test public API key.
const stripePromise = loadStripe(stripeKey);

interface Props {}

const CheckoutPage: React.FC<Props> = () => {
  const [clientSecret, setClientSecret] = useState('');

  const getClientSecret = async () => {
    const res = await api.subscription.createCheckoutSession();
    if (res.data) {
      setClientSecret(res.data.clientSecret);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      getClientSecret();
    })
  }, []);


  return (
    <main className="content">
      <div className="box" style={{ minHeight: '70vh' }}>
        <div id="checkout">
          {clientSecret && (
            <EmbeddedCheckoutProvider stripe={stripePromise} options={{ clientSecret }}>
              <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
          )}
        </div>
      </div>
    </main>
  );
};

export default CheckoutPage;
