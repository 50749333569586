import { baseMethods } from ".";
import { User } from "./auth";

export interface AuditLog {
  id: string
  createdAt: Date;
  user: User;
  companyId: string
  entityType: 'CASE' | 'CASE_ITEM' | 'CASE_STEP' | 'LETTER_TEMPLATE'
  entityId: string
  log: string
  data: Record<string, string | number | boolean>
}

export const list = (entityType: AuditLog['entityType'], entityId: string) => {
  return baseMethods.get<AuditLog[]>({
    path: `/${entityType.toLowerCase()}/${entityId}/audit`,
  });
}
