import { BoltIcon } from '@heroicons/react/24/outline';
import { Step } from '../../api/process';
import { ownerGroupToTitleCase } from '../../modules/case/utils';

interface Props {
  step: Step & {
    current?: boolean;
  };
}

const DecisionStep: React.FC<Props> = ({ step }) => {
  return (
    <div className={`decision-node ${step.current ? 'decision-node--current' : ''}`}>
      <div className="decision-node__text">
        <span >{step.name}</span>
        <ul className="step-node__owners">
          {step.ownerGroups.map((ownerGroup) => {
            return (
              <li key={ownerGroup} className="step-node__owner">
                {ownerGroupToTitleCase(ownerGroup)}
              </li>
            );
          })}
        </ul>
        {(!!step.automation || step.autoDecisionFormula) && <BoltIcon className="step-node__icon" />}
      </div>
    </div>
  );
};

export default DecisionStep;
