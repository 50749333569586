import { useState } from 'react';
import Input, { InputProps } from './Input';
import { TrashIcon } from '@heroicons/react/24/outline';
import './TagInput.css';
import { sentence } from 'case';

type Props = InputProps & {
  emptyMessage?: string
};

const TagInput: React.FC<Props> = ({
  value,
  onChange,
  labelText,
  id,
  helpText,
  readOnly,
  type,
  small,
  emptyMessage,
}) => {
  const [newTag, setNewTag] = useState<string>('');

  const handleAddNewTag = () => {
    onChange([...tagArray, newTag] as unknown as string);
    setNewTag('');
  };
  const tagArray = value as unknown as string[];
  return (
    <div className="tag-input__container">
      <p className="tag-input__label">{labelText}</p>
      <div className="tag-input__tag-container">
        {tagArray.map((tag) => (
          <span key={tag} className="tag-input__tag">
            {tag}
            {!readOnly && (
              <button
                type="button"
                className="link-button"
                onClick={() => onChange(tagArray.filter((t) => t !== tag) as unknown as string)}
              >
                <TrashIcon />
              </button>
            )}
          </span>
        ))}
        {(!tagArray.length && !!emptyMessage) && (
          <p className='tag-input__empty-message'>{emptyMessage}</p>
        )}
      </div>

      {!readOnly && (
        <div className="tag-input__input-container">
          <Input
            id={id}
            labelText={sentence(`Add ${labelText}`)}
            value={newTag}
            onChange={setNewTag}
            helpText={helpText}
            readOnly={readOnly}
            type={type}
            small={small}
          />
          <button
            disabled={!newTag}
            onClick={handleAddNewTag}
            type="button"
            className="button button--small button--secondary tag-input__button"
          >
            Add
          </button>
        </div>
      )}
    </div>
  );
};

export default TagInput;
