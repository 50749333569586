import Axios, { AxiosError, ResponseType } from 'axios'
import { IClearToastFn, ISetToastFn } from '../App'
import * as auth from './auth'
import * as processes from './process'
import * as cases from './case'
import * as users from './user'
import * as files from './file'
import * as audit from './audit'
import * as notes from './note'
import * as search from './search'
import * as subscription from './subscription'
import * as integration from './integration'
import * as analytics from './analytics'

export const baseURL = String(process.env.REACT_APP_API_URL || 'http://localhost:9000') // || 'https://z8c6mx2pki.eu-west-1.awsapprunner.com')
const axios = Axios.create({
    baseURL
})

let setToastFn: ISetToastFn
let clearToastFn: IClearToastFn

export const init = (setToastFnInput: ISetToastFn, clearToastFnInput: IClearToastFn, jwt?: string) => {
    // const superUserSelectedCustomer = window.localStorage.getItem('dodyl-super-customer-id')

    setToastFn = setToastFnInput;
    clearToastFn = clearToastFnInput;

    axios.defaults.headers['x-jwt-auth'] = jwt!
}

const handleError = (err: AxiosError<any>, userFacingMessage?: CalcUserMessageFunc) => {
    console.error(err)
    let header = 'Oops!'
    let message =  'Something has gone wrong, please try again.'
    const status = err.response?.status || 500
    const code = err.response?.data?.code
    let bypassToast = false

    if (userFacingMessage) {
        const res = userFacingMessage(status, code)
        header = res ? res.header : header
        message = res ? res.message : message
        bypassToast = res ? !!res.bypassToast : bypassToast
    }

    if (!userFacingMessage && err.response?.data.message) {
        message = err.response.data.message;
    }
    if (!bypassToast) {
        setToastFn({ type: 'error', header, message, clearAfterMs: 3000 })
    }

    return {
        status,
        code
    }
}

type ApiCode = 
    'SIGNUP_REQUIRED'
    | 'INVALID_OTP'
    | 'TEMPLATE_NOT_FOUND'
    | 'CASE_NOT_FOUND'
    | 'SECTION_INCOMPLETE'
    | 'SECTION_ALREADY_APPROVED'
    | 'INVALID_DATA'
    | 'USER_NOT_FOUND'
    | 'USER_ALREADY_HAS_ACCESS'
    | 'RECORD_NOT_FOUND'
    | 'INVALID_EMAIL'
    | 'NOTE_NOT_PROVIDED'
    | 'INVALID_FILE_EXTENSION'

interface ApiErrorResponse {
    status: number
    code: ApiCode
}

type CalcUserMessageFunc = (statusCode: number, apiCode?: ApiCode) => { header: string, message: string, bypassToast?: boolean } | void

interface IRequestParams {
    path: string,
    params?: any
    body?: any
    errorMessage?: CalcUserMessageFunc
    loadingMessage?: string,
    headers?: object
    responseType?: ResponseType
    skipLoadingToast?: boolean
}

export interface ApiResponse<Data> {
    error?: ApiErrorResponse
    data?: Data
}

const get = async <Data>(requestParams: IRequestParams): Promise<ApiResponse<Data>> => {
    let loadingToastId: string = ''
    try {
        if (!requestParams.skipLoadingToast) loadingToastId = setToastFn({ type: 'loading', message: requestParams.loadingMessage || 'Loading'})
        const res = await axios.get<Data>(requestParams.path, { params: requestParams.params, headers: requestParams.headers, responseType: requestParams.responseType })
        if (!requestParams.skipLoadingToast) clearToastFn(loadingToastId)
        return { data: res.data }
    } catch (error: any) {
        if (!requestParams.skipLoadingToast) clearToastFn(loadingToastId)
        return { error: handleError(error, requestParams.errorMessage) }
    }
}

const post = async <Data>(requestParams: IRequestParams): Promise<ApiResponse<Data>> => {
    let loadingToastId: string = ''
    try {
        if (!requestParams.skipLoadingToast) loadingToastId = setToastFn({ type: 'loading', message: requestParams.loadingMessage || 'Loading'})
        const res = await axios.post<Data>(requestParams.path, requestParams.body)
        if (!requestParams.skipLoadingToast) clearToastFn(loadingToastId)
        return { data: res.data }
    } catch (error: any) {
        if (!requestParams.skipLoadingToast) clearToastFn(loadingToastId)
        return { error: handleError(error, requestParams.errorMessage) }
    }
}

const put = async <Data>(requestParams: IRequestParams): Promise<ApiResponse<Data>> => {
    let loadingToastId: string = ''
    try {
        if (!requestParams.skipLoadingToast) loadingToastId = setToastFn({ type: 'loading', message: requestParams.loadingMessage || 'Loading'})
        const res = await axios.put<Data>(requestParams.path, requestParams.body)
        if (!requestParams.skipLoadingToast) clearToastFn(loadingToastId)
        return { data: res.data }
    } catch (error: any) {
        if (!requestParams.skipLoadingToast) clearToastFn(loadingToastId)
        return { error: handleError(error, requestParams.errorMessage) }
    }
}

const patch = async <Data>(requestParams: IRequestParams): Promise<ApiResponse<Data>> => {
    let loadingToastId: string = ''
    try {
        if (!requestParams.skipLoadingToast) loadingToastId = setToastFn({ type: 'loading', message: requestParams.loadingMessage || 'Loading'})
        const res = await axios.patch<Data>(requestParams.path, requestParams.body)
        if (!requestParams.skipLoadingToast) clearToastFn(loadingToastId)
        return { data: res.data }
    } catch (error: any) {
        if (!requestParams.skipLoadingToast) clearToastFn(loadingToastId)
        return { error: handleError(error, requestParams.errorMessage) }
    }
}

const del = async <Data>(requestParams: IRequestParams): Promise<ApiResponse<Data>> => {
    let loadingToastId: string = ''
    try {
        if (!requestParams.skipLoadingToast) loadingToastId = setToastFn({ type: 'loading', message: requestParams.loadingMessage || 'Loading'})
        const res = await axios.delete<Data>(requestParams.path, { params: requestParams.params, data: requestParams.body })
        if (!requestParams.skipLoadingToast) clearToastFn(loadingToastId)
        return { data: res.data }
    } catch (error: any) {
        if (!requestParams.skipLoadingToast) clearToastFn(loadingToastId)
        return { error: handleError(error, requestParams.errorMessage) }
    }
}

export const baseMethods = {
    get,
    post,
    put,
    patch,
    del
}

export {
    auth,
    processes,
    cases,
    users,
    files,
    audit,
    notes,
    search,
    subscription,
    integration,
    analytics,
}