import { Fragment, useContext, useState } from 'react'
import { Case, CaseItem } from '../../../api/case'
import CaseItemInput from './CaseItemInput'
import Table, { TableHeader } from '../../../components/table/Table'
import { lower, title } from 'case'
import * as api from '../../../api'
import './CaseItems.css'
import { Process } from '../../../api/process'
import CaseItemSpreadsheetView from './CaseItemSpreadsheetView'
import CaseItemCsvUpload from './CaseItemCsvUpload'
import InfoBanner from '../../../components/info-banner/InfoBanner'
import { ExclamationTriangleIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/outline'
import { AppContext } from '../../../App'
import { Link } from 'react-router-dom'
import Modal from '../../../components/modal/Modal'

interface Props {
  caseId: string
  caseItems: CaseItem[]
  process: Process
  canEdit?: boolean
  canAdd: boolean
  updateCase: (updates: Partial<Case>) => void
}

const CaseItems: React.FC<Props> = ({ caseId, caseItems, process, canEdit, canAdd, updateCase }) => {
  const ctx = useContext(AppContext)
  const [mode, setMode] = useState<'STANDARD' | 'SPREADSHEET'>('STANDARD')
  const [showAdd, setShowAdd] = useState<boolean>(!caseItems.length && canAdd)
  const [selectedItem, setSelectedItem] = useState<any>();

  const handleNewItem = async (newItem: any) => {
    const res = await api.cases.createItem(caseId, newItem)
    if (res.data) {
      updateCase({ items: [...caseItems, res.data] })
    }
  }

  const handleDeleteItem = async (itemToDelete: CaseItem) => {
    const res = await api.cases.deleteItem(caseId, itemToDelete.id)
    if (!res.error) {
      const updatedItems = caseItems.filter((item) => item.id !== itemToDelete.id)
      updateCase({ items: updatedItems })
    }
  }

  const handleBulkUpdate = async (items: CaseItem[]) => {
    const res = await api.cases.upsertItems(caseId, items)
    if (res.data) {
      updateCase(res.data)
    }
  }

  const handleUpdate = async (updatedData: any) => {
    if (!selectedItem) return

    const res = await api.cases.updateItem(caseId, selectedItem.id, updatedData)
    if (!res.error) {
      const updatedCaseItems = caseItems.map((caseItem) => {
        if (caseItem.id !== selectedItem.id) {
          return caseItem;
        }

        return {
          ...caseItem,
          data: updatedData
        }
      })
      updateCase({ items: updatedCaseItems })
      setSelectedItem(undefined)
    }
  }

  const itemsToDisplay = caseItems.map((item) => {
    return {
      id: item.id,
      ...item.data,
    }
  })

  const tableHeaders: TableHeader[] = [
    ...Object.keys(itemsToDisplay[0] || {}),
    (caseItem) => {
      return {
        header: ' ',
        element: (
          <div>
            <button className="icon-button" type="button" onClick={() => handleDeleteItem(caseItem)}>
              <TrashIcon />
            </button>
            <button className="icon-button" type="button" onClick={() => setSelectedItem(caseItem)}>
              <PencilIcon />
            </button>
          </div>
        ),
      }
    },
  ]

  const reachedMaxItems = !!process.maxItems && caseItems.length >= process.maxItems
  const itemNamePrefix = process.maxItems === 1 ? '' : 's'
  const itemName = title(`${process.itemName}${itemNamePrefix}`)
  return (
    <Fragment>
      <div className="case-items__header">
        <h3 className="case-items__name">{itemName || 'Case Items'}</h3>
        <div>
          {canAdd && !reachedMaxItems && !!process.itemFields.length && (
            <CaseItemCsvUpload process={process} updateCase={updateCase} caseId={caseId} />
          )}
          {/* <button style={{marginLeft: '16px'}} onClick={() => setMode(mode === 'SPREADSHEET' ? 'STANDARD' : 'SPREADSHEET')} className='link-button'>Switch to {mode === 'SPREADSHEET' ? 'standard' : 'spreadsheet'} input</button> */}
        </div>
      </div>
      {!process.itemFields.length && (
        <InfoBanner
          variant="warning"
          icon={<ExclamationTriangleIcon />}
          message={
            ctx.user.roles.includes('admin') ? (
              <span>
                Case items haven't been configured yet, you can do this in{' '}
                <Link to={`/admin/process/${process.id}`}>process admin</Link>.
              </span>
            ) : (
              "Case items haven't been configured yet, contact your administrator to fix this"
            )
          }
        />
      )}
      {!!process.itemFields.length && (
        <Fragment>
          {canAdd && !reachedMaxItems && mode === 'STANDARD' && (
            <Fragment>
              <button className="link-button" onClick={() => setShowAdd(!showAdd)}>
                {showAdd ? 'Close' : `Add more ${lower(itemName)}`}
              </button>
              {showAdd && (
                <Fragment>
                  <h4 className="case-items__add-header">
                    Add {lower(process.itemName)}
                    {itemNamePrefix}
                  </h4>
                  <CaseItemInput itemFields={process.itemFields} onItemAdded={handleNewItem} />
                </Fragment>
              )}
            </Fragment>
          )}

          <div className="case-items__data-container">
            {mode === 'STANDARD' && (
              <Table
                headers={tableHeaders}
                data={itemsToDisplay}
                search={itemsToDisplay.length > 6}
              />
            )}

            {mode === 'SPREADSHEET' && (
              <CaseItemSpreadsheetView
                caseId={caseId}
                caseItems={caseItems}
                process={process}
                canEdit={canEdit}
                canAdd={canAdd}
                onSave={handleBulkUpdate}
              />
            )}
          </div>

          {reachedMaxItems && canAdd && (
            <p className="info-text">
              You can only add {process.maxItems} {itemName}
            </p>
          )}
        </Fragment>
      )}
      <Modal handleClose={() => setSelectedItem(undefined)} isOpen={!!selectedItem} modalStyles={{ minHeight: '50vh' }}>
        <h3>Edit {itemName}</h3>
        <CaseItemInput itemFields={process.itemFields} onItemUpdated={(newItem) => handleUpdate(newItem)} existingItem={selectedItem} />
      </Modal>
      
    </Fragment>
  )
}

export default CaseItems
