import { Link } from 'react-router-dom';
import { SearchResult } from '../../api/search';
import './SearchResultItem.css';
import { title } from 'case';

interface Props {
  searchResult: SearchResult;
}

const SearchResultItem: React.FC<Props> = ({ searchResult }) => {
  return (
    <div className="search-result__container">
      <Link className='search-result__link' to={`/case/${searchResult.case.id}`}>
        {searchResult.case.code} {searchResult.case.process.name}
      </Link>
      <ul className='search-result__data'>
        {Object.keys(searchResult.relevantData).map((key) => {
          return (
            <li
              dangerouslySetInnerHTML={{ __html: `${title(key)}: ${searchResult.relevantData[key]}` }}
            ></li>
          );
        })}
      </ul>
    </div>
  );
};

export default SearchResultItem;
