import { FormEvent, useState } from 'react';
import { ItemField } from '../../../../api/process';
import ItemFieldConfigList from '../../configure-item-field/ItemFieldConfigList';

interface Props {
  supportingData: ItemField[];
  onSave: (supportingData: ItemField[]) => void | Promise<void>;
}

const EditStepData: React.FC<Props> = ({ supportingData, onSave }) => {
  const [editedSupportingData, setEditedSupportingData] = useState<ItemField[]>(supportingData);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    onSave(editedSupportingData);
  }

  return (
    <form onSubmit={handleSubmit}>
      <h2 style={{ margin: 0, marginBottom: '8px' }}>Step data</h2>
      <p style={{ margin: 0, marginBottom: '16px' }}>
        This is a set of fields to capture data inside a specific step that would be useful to record.
        </p>
      <ItemFieldConfigList
        labelText=""
        itemFields={editedSupportingData || []}
        onChange={(supportingData) => setEditedSupportingData(supportingData)}
      />
      <button className='button'>Save</button>
    </form>
  );
};

export default EditStepData;
