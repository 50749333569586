import { Fragment, useState } from 'react';
import { Automation, Process, Step } from '../../../api/process';
import Input from '../../../components/input/Input';
import { camel } from 'case';
import ItemFields from '../../../components/item-field-form/ItemFields';
import AvailableFields from './AvailableFields';
import './ConfigureAutomation.css';
import Select from '../../../components/input/Select';
import RequestAutomationForm from './RequestAutomationForm';
import ConfigureCsvDownload from './ConfigureCsvDownload';

interface Props {
  process: Process;
  stepId: string;
  onChange: (stepAutomation: Automation) => void;
  stepAutomation?: Automation;
  allSteps: Step[];
  onSave: () => void;
}

const ConfigureAutomation: React.FC<Props> = ({
  stepAutomation,
  onChange,
  stepId,
  process,
  allSteps,
  onSave,
}) => {
  const [automationType, setAutomationType] = useState<'LETTER' | 'REQUEST' | 'CSV_DOWNLOAD'>(stepAutomation?.type || 'REQUEST') 
  const handleChange = (updates: any) => {
    onChange({
      type: 'LETTER',
      ...stepAutomation,
      ...updates,
      letter: {
        ...(stepAutomation?.letter || {}),
        ...(updates?.letter || {}),
      } as Automation['letter'],
    });
  };
  return (
    <Fragment>
      <div className="item-field-config">
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h2>Configure automation</h2>
        </div>
        <Select
          id="automation-type"
          labelText="Automation type"
          value={automationType}
          onChange={(value) => setAutomationType(value as any)}
          options={[
            { value: 'LETTER', label: 'Document' },
            { value: 'CSV_DOWNLOAD', label: 'CSV Download' },
            { value: 'REQUEST', label: 'Custom' },
          ]}
          style={{ width: '40%', marginBottom: '16px' }}
        />
        {automationType === 'REQUEST' && (
          <RequestAutomationForm processId={process.id} processStepId={stepId} />
        )}
        {automationType === 'LETTER' && (
          <div className='configure-automation__letter-container'>
            <div className='configure-automation__letter-fields'>
              <ItemFields
                entityId={stepId}
                entityType="LETTER_TEMPLATE"
                isEditing={true}
                values={stepAutomation?.letter || {}}
                fields={[{ name: 'templateFileId', type: 'file', requiredOnCreate: true }]}
                setValues={(letter) => handleChange({ letter })}
              />
              <Input
                id="automation-letter-name"
                labelText="Document Name"
                value={stepAutomation?.letter?.name || ''}
                onChange={(name) => handleChange({ letter: { name: name } })}
                helpText={`Will be saved as ${camel(stepAutomation?.letter?.name || '')}`}
              />
              <Input
                id="automation-step-data-required"
                labelText="Does step data need to be completed before generating the document?"
                type="checkbox"
                value={stepAutomation?.letter?.requiresCurrentStepData ? 'true' : 'false'}
                onChange={(requiresCurrentStepData) =>
                  handleChange({
                    letter: {
                      requiresCurrentStepData: requiresCurrentStepData === 'true',
                    },
                  })
                }
              />
              <Input
                id="automation-one-document-per-item"
                labelText="Do you want to generate one document per item?"
                type="checkbox"
                value={stepAutomation?.letter?.letterPerItem ? 'true' : 'false'}
                onChange={(letterPerItem) =>
                  handleChange({
                    letter: {
                      letterPerItem: letterPerItem === 'true',
                    },
                  })
                }
              />
              <small style={{ marginTop: '-16px', marginBottom: '16px', display: 'block' }}>{`Leaving this unchecked will render a list of ${process.itemName}s in the letter wherever you reference a ${process.itemName}s variable.`}</small>
              <button onClick={onSave} className='button'>Save</button>
            </div>
            <div className='configure-automation__letter-info'>
              <AvailableFields
                process={process}
                currentStepId={stepId}
                allSteps={allSteps}
                requiresCurrentStepData={stepAutomation?.letter?.requiresCurrentStepData}
              />
            </div>
          </div>
        )}
        {automationType === 'CSV_DOWNLOAD' && (
          <ConfigureCsvDownload
            process={process}
            stepId={stepId}
            onChange={onChange}
            stepAutomation={stepAutomation}
            allSteps={allSteps}
            onSave={onSave}
          />
        )}
      </div>
    </Fragment>
  );
};

export default ConfigureAutomation;
