import { FormEvent, useContext, useState } from 'react'
import * as api from '../../../api'
import TagInput from '../../../components/input/TagInput'
import { AppContext } from '../../../App'

interface Props {}

const ProcessCatogoryAdmin: React.FC<Props> = () => {
  const ctx = useContext(AppContext)

  const [categories, setCategories] = useState<string[]>(ctx.user.company.processCategories)

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()

    const res = await api.users.admin.updateCompany({ processCategories: categories })
    if (res.data) {
      ctx.setUser({
        ...ctx.user,
        company: {
          ...ctx.user.company,
          processCategories: categories,
        },
      })
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <TagInput
        value={categories as unknown as string}
        onChange={(categories) => setCategories(categories as unknown as string[])}
        id="process-categories-input"
        labelText="Process categories"
        helpText='Use these to help organise your processes'
        small={true}
      />
      <button className="button button--small">Save process categories</button>
    </form>
  )
}

export default ProcessCatogoryAdmin
