import { kebab } from 'case'
import { unparse } from 'papaparse'

interface Props {
  data: any[]
  exportName?: string
  buttonText?: string
}

const CsvDownload: React.FC<Props> = ({ data, exportName, buttonText }) => {

  const downloadCsv = () => {
    const csv = unparse(data)
    const fileName = `${kebab(`${new Date().toISOString()}-${exportName}`)}.csv`

    const hiddenElement = document.createElement('a')
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv)
    hiddenElement.target = '_blank'
    hiddenElement.download = fileName
    hiddenElement.click()
    hiddenElement.remove()
  }

  return <button onClick={downloadCsv}>{buttonText || 'Download CSV'}</button>
}

export default CsvDownload