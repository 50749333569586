import { HyperFormula } from 'hyperformula';
import { format, parseISO } from 'date-fns';
import { Case, CaseStep } from '../../../api/case';
import { flatten } from 'flatten-anything';

const hf = HyperFormula.buildEmpty({
  precisionRounding: 4,
  licenseKey: 'gpl-v3',
});
hf.addSheet();

type CaseData = {
  step: any;
  [key: string]: any;
};

const extractDataFromCase = (_case: Case, currentCaseStep: CaseStep) => {
  let caseData: CaseData = {
    case: _case.supportingData || {},
    step: {
      ...(currentCaseStep.supportingData || {}),
    },
  };
  // get case supporting data and step supporting data
  _case.caseSteps.forEach((caseStep) => {
    if (caseStep.id === currentCaseStep.id) {
      return;
    }
    caseData = {
      ...caseData,
      ...(caseStep.supportingData || {}),
    };
  });

  return caseData;
};
const transformValue = (inputFormula: string, value: string) => {
  if (inputFormula.includes('DATEDIF')) {
    try {
      return `"${formatDate(value)}"`;
    } catch (error) {
      return '';
    }
  }

  if (isNaN(Number(value))) {
    return `"${value}"`;
  }

  return value;
};

const handleFormulaError = (message: string, executedFormula: string, formulaValues: string[]) => {
  if (message === 'Start date needs to be earlier than end date.') {
    // swap start and end date values around, then re-calculate
    let newFormula = executedFormula.replace(formulaValues[0], 'VALUE_1');
    newFormula = newFormula.replace(formulaValues[1], 'VALUE_2');

    newFormula = newFormula.replace('VALUE_1', formulaValues[1]);
    newFormula = newFormula.replace('VALUE_2', formulaValues[0]);

    return hf.calculateFormula(newFormula, 0);
  }
};

const executeFormula = (inputFormula: string, _case: Case, currentStepId: string) => {
  const currentCaseStep = _case.caseSteps.find((caseStep) => caseStep.id === currentStepId);
  const caseData = extractDataFromCase(_case, currentCaseStep!);
  const flattenedData = flatten(caseData);

  return executeFormulaWithData(inputFormula, flattenedData);
};

export const executeFormulaWithData = (inputFormula: string, data: any) => {
  let formula = inputFormula;
  const dataKeys = Object.keys(data);

  const formulaValues: string[] = [];
  dataKeys.forEach((key) => {
    const searchRegex = new RegExp(`{{${key}}}`, 'gm');
    if (searchRegex.test(formula)) {
      let value = transformValue(inputFormula, (data as any)[key]);
      if (typeof value === 'string' && value.includes('£')) {
        value = value.replace('£', '');
      }

      if (typeof value === 'string' && value.includes(',')) {
        value = value.replace(/,/g, '');
      }

      formulaValues.push(value);
      formula = formula.replace(searchRegex, `${value}`);
    }    
  });
  
  try {
    let res = hf.calculateFormula(`=(${formula})`, 0);
    // @ts-ignore
    if (res?.message) {
      // @ts-ignore
      res = handleFormulaError(res.message, formula, formulaValues);
    }
    return res;
  } catch (error: any) {
    console.log(error.message)
    return undefined;
  }
}

const formatDate = (inputDate: string, withTime?: boolean) => {
  const formatString = `dd/MM/yyyy${withTime ? ' HH:mm' : ''}`;

  return format(parseISO(inputDate), formatString);
};

export default executeFormula;
