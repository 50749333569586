export const getBrand = () => {
  if (process.env.REACT_APP_BRAND) {
    return process.env.REACT_APP_BRAND as string
  }

  if (window.location.hostname.includes('operonix')) {
    return 'operonix'
  }

  if (window.location.hostname.includes('er-buddy')) {
    return 'er-buddy'
  }

  return 'operonix'
}