import { Fragment, useState } from 'react';
import Modal from '../../../components/modal/Modal';
import CsvUpload from '../../../components/csv-upload/CsvUpload';
import { Case, CaseItem } from '../../../api/case';
import { ItemField, Process } from '../../../api/process';
import { camel, title } from 'case';
import { validateValue } from '../utils';
import * as api from '../../../api'

interface Props {
  caseId: string;
  process: Process;
  updateCase: (updates: Partial<Case>) => void;
}

const CaseItemCsvUpload: React.FC<Props> = ({ process, updateCase, caseId }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);

  const handleCsvUpload = async (data: any[]) => {
    let errors: string[] = [];
    data.forEach((row, index) => {
      const keys = Object.keys(row);
      keys.forEach((key) => {
        const itemField = process.itemFields.find(
          (itemFieldToFind) => itemFieldToFind.name === camel(key),
        );
        if (!itemField) {
          errors.push(`Row ${index + 1}: Unable to match field for column ${key}`);
          return;
        }

        const isValidValue = validateValue(row[key], itemField.type);

        if (!isValidValue) {
          errors.push(
            `Row ${index + 1}: Incorrect data type for column ${key}, expected ${itemField.type}. Provided value: ${
              row[key]
            }`,
          );
        }
      });
    });

    setErrors(errors);
    if (!errors.length) {
      const items = await Promise.all(data.map(async (newItem) => {
        const res = await api.cases.createItem(caseId, newItem);
        return res.data;
      }));
      updateCase({ items: items.filter(Boolean) as CaseItem[] });
      setModalOpen(false);
    }
  };

  function generateCSVTemplate(): void {
    const csvContent = process.itemFields.map((field) => title(field.name)).join(',') + '\n';
    const encodedUri = encodeURI('data:text/csv;charset=utf-8,' + csvContent);

    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'template.csv');
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  }

  return (
    <Fragment>
      <button onClick={() => setModalOpen(true)} className="link-button">
        CSV Upload
      </button>
      <Modal isOpen={modalOpen} handleClose={() => setModalOpen(false)} modalStyles={{ width: '80vw', maxWidth: '1000px'}}>
        <h2>Upload {title(process.itemName)} CSV</h2>
        <button className="link-button" style={{marginBottom: '32px'}} onClick={generateCSVTemplate}>Download CSV template</button>
        <CsvUpload labelText="Upload csv" onParseComplete={handleCsvUpload} />
        {!!errors.length && (
          <Fragment>
            <h3>Errors detected in CSV, please correct and upload again</h3>
            <pre>{JSON.stringify(errors, null, 4)}</pre>
            <small>Date values should be provided in the format YYYY-MM-DD</small>
          </Fragment>
        )}
      </Modal>
    </Fragment>
  );
};

export default CaseItemCsvUpload;
