import { Fragment } from 'react';
import { Case } from '../../../api/case';
import { ItemField } from '../../../api/process';
import * as api from '../../../api';
import './SupportingInfo.css';
import ItemFieldForm from '../../../components/item-field-form/ItemFieldForm';

interface Props {
  caseId: string;
  supportingCaseData: Case['supportingData'];
  supportingDataFields: ItemField[];
  updateCase: (updates: Partial<Case>) => void;
  editOnRender: boolean;
}

//  Question - should this only be editable by the case owner?
const SupportingInfo: React.FC<Props> = ({
  caseId,
  supportingCaseData,
  supportingDataFields,
  updateCase,
  editOnRender,
}) => {
  const handleSubmit = async (supportingDataValues: any) => {
    await api.cases.updateSupportingInfo(caseId, supportingDataValues);
    updateCase({ supportingData: supportingDataValues });
  };

  return (
    <Fragment>
      <ItemFieldForm
        formTitle='Supporting Info'
        fields={supportingDataFields}
        initialValues={supportingCaseData}
        editOnRender={editOnRender}
        onSubmit={handleSubmit}
        caseId={caseId}
      />
    </Fragment>
  );
};

export default SupportingInfo;
