import { Fragment } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import './BuilderNav.css';

interface Props {}

const BuilderNav: React.FC<Props> = () => {
  const { id } = useParams();
  const location = useLocation();

  const navItems = [
    {
      path: `/admin/process/${id}`,
      name: 'Manage process',
    },
    {
      path: `/admin/process/${id}/builder`,
      name: 'Process builder',
    },
  ];

  return (
    <div className="content builder-nav__container">
      {navItems.map((navItem) => {
        const isActive = location.pathname === navItem.path;

        return (
          <Fragment key={navItem.path}>
            {!isActive && (
              <Link to={navItem.path} className="button button--small builder-nav__item">
                {navItem.name}
              </Link>
            )}
            {!!isActive && <span className="builder-nav__item">{navItem.name}</span>}
          </Fragment>
        );
      })}
    </div>
  );
};

export default BuilderNav;
