import { FormEvent, useEffect, useState } from 'react'
import { ItemField } from '../../../api/process'
import Input from '../../../components/input/Input'
import { title } from 'case'
import './CaseItemInput.css'
import Select from '../../../components/input/Select'
import { executeFormulaWithData } from '../case-step/utils'
import { validateValue } from '../../../components/item-field-form/utils'

interface Props {
  itemFields: ItemField[]
  onItemAdded?: (newItem: any) => void
  existingItem?: any
  onItemUpdated?: (updatedItem: any) => void
}

const CaseItemInput: React.FC<Props> = ({ itemFields, onItemAdded, existingItem, onItemUpdated }) => {
  const [newItem, setNewItem] = useState<any>(existingItem || {})

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    
    if (!existingItem && onItemAdded) {
      onItemAdded(newItem)
      setNewItem({})
    } else if (onItemUpdated) {
      onItemUpdated(newItem)
    }

    document.getElementById(itemFields[0].name)?.focus()
  }

  useEffect(() => {
    if (existingItem) {
      setNewItem(existingItem)
    }
  }, [existingItem])

  const formulaItemFields = itemFields.filter((itemField) => itemField.type === 'formula')

  const handleValueUpdate = (itemField: ItemField, value: string) => {
    let updatedData = { ...newItem, [itemField.name]: value }

    if (formulaItemFields.length) {
      formulaItemFields.forEach((formulaItemField) => {
        const updatedValue = executeFormulaWithData(formulaItemField.formula!, updatedData) || '';
        updatedData = { ...updatedData, [formulaItemField.name]: updatedValue }
      })
    }

    setNewItem(updatedData)
  }

  const canDisplayHorizontally = itemFields.length <= 5 && !existingItem

  return (
    <form
      onSubmit={handleSubmit}
      className={`case-item-input ${canDisplayHorizontally ? 'case-item-input--horizontal' : ''}`}
    >
      {itemFields.map((itemField) => {
        if (itemField.type === 'select') {
          return (
            <Select
              id={itemField.name}
              key={itemField.name}
              labelText={title(itemField.name)}
              value={newItem[itemField.name] || ''}
              onChange={(val) => handleValueUpdate(itemField, val)}
              options={itemField.options || []}
              invalid={!validateValue(itemField, newItem[itemField.name] || '')}
            />
          )
        }
        return (
          <Input
            id={itemField.name}
            key={itemField.name}
            labelText={itemField.label || title(itemField.name)}
            type={itemField.type}
            value={newItem[itemField.name] || ''}
            onChange={(val) => handleValueUpdate(itemField, val)}
            disabled={itemField.type === 'formula'}
            helpText={itemField.type === 'formula' ? 'Calculated' : ''}
            invalid={!validateValue(itemField, newItem[itemField.name] || '')}
            minLength={itemField.minLength}
            maxLength={itemField.maxLength}
          />
        )
      })}
      <button className="button button--small case-item-input__button">{existingItem ? 'Save' : 'Add'}</button>
    </form>
  )
}

export default CaseItemInput
